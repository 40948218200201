const fixture = [
  {
    "id": "cG9zdDoxMDc3MA==",
    "title": "Quantum-Touch Level 2",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-2",
    "excerpt": "This Quantum-Touch Level 2 workshop is in London spread over 2 days.",
    "datestimes": 'x1',
    "eventsMetaData": {
      "datetime": "2024-03-11_09:30:00",
      "datetimeend": "2024-03-12_17:30:00",
      "timezone": "GMT",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-march-in-london-2/",
      "location": "Healing House",
      "lastspaces": true,
      "lastfewdaysearlybird": "Live in London",
      "workshopName": "Quantum-Touch Level 2",
      "eventpassed": true,
      "price": 399,
      "priceSale": 349,
      "postalcode": "N22 8DH",
      "addresscountry": "GB",
      "addresslocality": "Bounds Green",
      "addressregion": "London",
      "locationdescription": "A hidden oasis in London! Originally the gardener’s cottage for a Victorian market garden, now a beautifully renovated house for events and workshops.",
      "stripeUrl": 'https://buy.stripe.com/28o5kpeLJd3i4PS19m',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/3cs6ot9rp9R60zC7xJ',
      "stripeUrlDeposit": 'https://buy.stripe.com/9AQ0058nlaVacik8BP',
      "stripeUrlRepeat": 'https://buy.stripe.com/bIYdQV0UT9R63LO2ds',
      "lunch": true
    }
  },
  {
    "id": "cG9zdDoxMDc2MA==",
    "title": "Quantum-Touch Level 2",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-2",
    "excerpt": "This Quantum-Touch Level 2 workshop is online and spread over 4 sessions, each session is just over 3 hours long.",
    "datestimes": "\n<ul>\n<li>Tuesday, 7th May at 18:00 &#8211; 21:15 BST</li>\n\n\n\n<li>Wednesday, 8th May at 18:00 &#8211; 21:15 BST</li>\n\n\n\n<li>Monday, 13th May at 18:00 &#8211; 21:15 BST</li>\n\n\n\n<li>Wednesday, 15th May at 18:00 &#8211; 21:15 BST</li>\n</ul>\n",
    "eventsMetaData": {
      "datetime": "2024-05-07_18:00:00",
      "datetimeend": "2024-05-14_21:15:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-may-online/",
      "location": "Zoom",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "workshopName": "Quantum-Touch Level 2",
      "eventpassed": null,
      "price": 399,
      "priceSale": 349,
      "postalcode": null,
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "stripeUrl": 'https://buy.stripe.com/28o5kpeLJd3i4PS19m',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/3cs6ot9rp9R60zC7xJ',
      "stripeUrlDeposit": 'https://buy.stripe.com/9AQ0058nlaVacik8BP',
      "stripeUrlRepeat": 'https://buy.stripe.com/bIYdQV0UT9R63LO2ds',
    }
  },
  {
    "id": "cG9zdDoxMTIyNw==",
    "title": "Energy Boost Mini Class",
    "uri": "/events/quantum-touch",
    "eventType": "qt-energy-boost",
    "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
    "datestimes": '',
    "eventsMetaData": {
      "datetime": "2024-05-20_19:30:00",
      "datetimeend": "2024-05-20_21:30:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
      "location": "Zoom",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "workshopName": "Quantum-Touch Energy Boost",
      "eventpassed": null,
      "price": 45,
      "priceSale": null,
      "postalcode": null,
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
    }
  },
  {
    "id": "cG9zdDoxMDc0OA==",
    "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 1: Becoming Energized",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-1",
    "excerpt": "This LIVE Quantum-Touch Level 1 workshop is spread over four 3-hour sessions.",
    "datestimes": '<ul><li>Wednesday 19th June at 18:00 – 21:15 BST</li><li>Thursday 20th June at 18:00 – 21:15 BST</li><li>Tuesday 25th June at 18:00 – 21:15 BST</li><li>Wednesday 26th June at 18:00 – 21:15 BST</li></ul>',
    "eventsMetaData": {
      "datetime": "2024-06-19_18:00:00",
      "datetimeend": "2024-06-25_21:15:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-1-june-online",
      "location": "Zoom",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "eventpassed": null,
      "price": 320,
      "priceSale": 265,
      "postalcode": null,
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "workshopName": "Quantum-Touch Level 1",
      "stripeUrl": 'https://buy.stripe.com/fZe4gleLJ2oE5TW8BF',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/4gw005eLJ8N2cik7ym',
      "stripeUrlDeposit": 'https://buy.stripe.com/aEU3ch9rpaVa3LOaJT',
      "stripeUrlRepeat": 'https://buy.stripe.com/28ofZ39rpe7mcikaJU',
    }
  },
  {
    "id": "cG9zdDoxMDc2NQ==",
    "title": "Quantum-Touch Supercharging",
    "uri": "/events/quantum-touch",
    "eventType": "qt-supercharging",
    "excerpt": "This Quantum-Touch Supercharging workshop Live in London. Starting on Monday 8th July, spread over 2 days.",
    "datestimes": '<ul>\n<li>Monday 8th July - 09:30 – 17:30</li>\n\n\n\n<li>Tuesday 9th July - 09:30 – 17:30</li>\n</ul>',
    "eventsMetaData": {
      "datetime": "2024-07-08_09:30:00",
      "datetimeend": "2024-07-09_17:30:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-march-in-london/",
      "location": "Healing House",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "eventpassed": null,
      "price": 399,
      "priceSale": 349,
      "postalcode": "N22 8DH",
      "addresscountry": "GB",
      "addresslocality": "Bounds Green",
      "addressregion": "London",
      "locationdescription": "A hidden oasis in London! Originally the gardener’s cottage for a Victorian market garden, now a beautifully renovated house for events and workshops.",
      "workshopName": "Quantum-Touch Supercharging",
      "stripeUrl": 'https://buy.stripe.com/6oEbIN0UT4wMbeg7xU',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/cN24gleLJbZe3LOdWh',
      "stripeUrlDeposit": 'https://buy.stripe.com/fZedQVcDB2oEeqs19t',
      "stripeUrlRepeat": 'https://buy.stripe.com/7sI005fPN0gwgyA2dy',
      "lunch": true,
      "upload_gallery": {
        "venueGallery": [
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBP/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdOYd2lDrM0zAP/EABwQAAEEAwEAAAAAAAAAAAAAABEAAQIDBBAhE//aAAgBAQABBQK/qlIvj2Q8xwBhouV//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAIQEf/aAAgBAwEBPwFqVf/EABgRAAIDAAAAAAAAAAAAAAAAAAABAhAR/9oACAECAQE/AWYRr//EABsQAAICAwEAAAAAAAAAAAAAAAARECICMUFC/9oACAEBAAY/Am0hvyXyscjgzcf/xAAcEAACAwEAAwAAAAAAAAAAAAABEQAhMVFBYYH/2gAIAQEAAT8hQAX2QajJQV5gV6xdZCC5I2pjAIWwdAvbiFoH2ESMo5//2gAMAwEAAgADAAAAENcvPf/EABkRAQEBAAMAAAAAAAAAAAAAAAEAESExUf/aAAgBAwEBPxA4MWB7dYDL/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERIWH/2gAIAQIBAT8QTS+mmNun/8QAHRABAAMAAwADAAAAAAAAAAAAAQARITFBkWFx8f/aAAgBAQABPxAGFDOF+RCisG3JXPIM3mD5cQgHRHC/bpBFQVBt/YiWH1hnyVFxmNAiVXQ0Mya1J//Z"
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/9a971bc2f452343aad203bf923932692/261e2/healing-house-14-min.jpg",
                      "srcSet": "/static/9a971bc2f452343aad203bf923932692/6f45c/healing-house-14-min.jpg 3w,\n/static/9a971bc2f452343aad203bf923932692/f8e2e/healing-house-14-min.jpg 5w,\n/static/9a971bc2f452343aad203bf923932692/261e2/healing-house-14-min.jpg 10w,\n/static/9a971bc2f452343aad203bf923932692/f4935/healing-house-14-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/9a971bc2f452343aad203bf923932692/fda91/healing-house-14-min.webp 3w,\n/static/9a971bc2f452343aad203bf923932692/fadb2/healing-house-14-min.webp 5w,\n/static/9a971bc2f452343aad203bf923932692/bd5c1/healing-house-14-min.webp 10w,\n/static/9a971bc2f452343aad203bf923932692/264f2/healing-house-14-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUBBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIBAP/aAAwDAQACEAMQAAABM4iqzCMAS0Rp/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAAxASEyEi/9oACAEBAAEFAjXQCrq04jLmrE3WK/WEPn//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/ATXf/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/ASP/xAAdEAEAAgAHAAAAAAAAAAAAAAABABECEiAhMTKR/9oACAEBAAY/AuGZBSd8fsKqGxp//8QAGhABAQEBAQEBAAAAAAAAAAAAAQARMUFR0f/aAAgBAQABPyFF2OIv7Zwdb7QHA3vbxA+obBnFbIsyYX//2gAMAwEAAgADAAAAEEAgf//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBFh/9oACAEDAQE/EFSNBR//xAAYEQEAAwEAAAAAAAAAAAAAAAABABARUf/aAAgBAgEBPxB1ZnEa/8QAHhABAQEAAgEFAAAAAAAAAAAAAREAITGBQVFhcaH/2gAIAQEAAT8Q4yE6Iv3hjeC28/K1n5kXwkoTxM8PQQ064982DzgGMyYBKveCrxiLnsUfW7//2Q=="
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/8e8a72d777dd58627a29f986024cbae4/261e2/healing-house-13-min.jpg",
                      "srcSet": "/static/8e8a72d777dd58627a29f986024cbae4/6f45c/healing-house-13-min.jpg 3w,\n/static/8e8a72d777dd58627a29f986024cbae4/f8e2e/healing-house-13-min.jpg 5w,\n/static/8e8a72d777dd58627a29f986024cbae4/261e2/healing-house-13-min.jpg 10w,\n/static/8e8a72d777dd58627a29f986024cbae4/f4935/healing-house-13-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/8e8a72d777dd58627a29f986024cbae4/fda91/healing-house-13-min.webp 3w,\n/static/8e8a72d777dd58627a29f986024cbae4/fadb2/healing-house-13-min.webp 5w,\n/static/8e8a72d777dd58627a29f986024cbae4/bd5c1/healing-house-13-min.webp 10w,\n/static/8e8a72d777dd58627a29f986024cbae4/264f2/healing-house-13-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQADAQAAAAAAAAAAAAAAAAABAgQD/9oADAMBAAIQAxAAAAHXRGclNDWpx4oZJgj/xAAbEAADAQEAAwAAAAAAAAAAAAAAAQISAxETIv/aAAgBAQABBQKfgvFNonpDrXPwvUzCMSYk/8QAFREBAQAAAAAAAAAAAAAAAAAAICL/2gAIAQMBAT8Bkf/EABYRAQEBAAAAAAAAAAAAAAAAAAECIP/aAAgBAgEBPwFq1x//xAAeEAACAQMFAAAAAAAAAAAAAAAAAQIREiEQMUFxgf/aAAgBAQAGPwI57qYm2VvQ1dF+mHHXY//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExUWFB/9oACAEBAAE/Ic223PSk6t1pBB5neDBfe2O0Y7+iGwja+Dbg8J//2gAMAwEAAgADAAAAEOMPff/EABkRAQACAwAAAAAAAAAAAAAAAAEAERAhMf/aAAgBAwEBPxCgWIsTfc//xAAYEQEAAwEAAAAAAAAAAAAAAAABABARIf/aAAgBAgEBPxDpByDhf//EAB4QAAICAgMBAQAAAAAAAAAAAAEhABExQXGRwVGx/9oACAEBAAE/EAZdkBb1kqAHDsCh4KPUX6TYPJdTUKQL4LmRGVQTshD+fTofsaC7gFR+YKPGf//Z"
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/9f421a75e802b60b3b4da42665de5a7c/261e2/healing-house-19-min.jpg",
                      "srcSet": "/static/9f421a75e802b60b3b4da42665de5a7c/6f45c/healing-house-19-min.jpg 3w,\n/static/9f421a75e802b60b3b4da42665de5a7c/f8e2e/healing-house-19-min.jpg 5w,\n/static/9f421a75e802b60b3b4da42665de5a7c/261e2/healing-house-19-min.jpg 10w,\n/static/9f421a75e802b60b3b4da42665de5a7c/f4935/healing-house-19-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/9f421a75e802b60b3b4da42665de5a7c/fda91/healing-house-19-min.webp 3w,\n/static/9f421a75e802b60b3b4da42665de5a7c/fadb2/healing-house-19-min.webp 5w,\n/static/9f421a75e802b60b3b4da42665de5a7c/bd5c1/healing-house-19-min.webp 10w,\n/static/9f421a75e802b60b3b4da42665de5a7c/264f2/healing-house-19-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAMCAP/aAAwDAQACEAMQAAABtDg0CSa6zwOk1agK/wD/xAAbEAADAQADAQAAAAAAAAAAAAABAgMAERITQf/aAAgBAQABBQL37YczD0cNKum5ZjIsfooy5aEj/8QAFhEAAwAAAAAAAAAAAAAAAAAAAREg/9oACAEDAQE/AQo//8QAGREAAgMBAAAAAAAAAAAAAAAAAAECEBFR/9oACAECAQE/AZN8NjWI/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQACESEQIjFBgf/aAAgBAQAGPwI1SJb2rEKxJWrD6p0wHDnH/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERITFBUZH/2gAIAQEAAT8htZnij5W24ycQ0IN/Ug9KiV2oPrRfFeDzHssTgt3D/9oADAMBAAIAAwAAABD7H4P/xAAYEQEBAQEBAAAAAAAAAAAAAAABEQAQQf/aAAgBAwEBPxAzbHIrvef/xAAYEQEBAQEBAAAAAAAAAAAAAAABABExYf/aAAgBAgEBPxALhogjNnl5X//EABwQAQACAwEBAQAAAAAAAAAAAAEAESFRYTFx8P/aAAgBAQABPxAH0r7ZuU00eUK3+3MaNKNt9gOwNYyd0p2M2WJSHW/ke9F4pGpdVImCRmnWWm4Fznk//9k="
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/e848534a83c536019a8011ddde743d64/261e2/healing-house-15-min.jpg",
                      "srcSet": "/static/e848534a83c536019a8011ddde743d64/6f45c/healing-house-15-min.jpg 3w,\n/static/e848534a83c536019a8011ddde743d64/f8e2e/healing-house-15-min.jpg 5w,\n/static/e848534a83c536019a8011ddde743d64/261e2/healing-house-15-min.jpg 10w,\n/static/e848534a83c536019a8011ddde743d64/f4935/healing-house-15-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/e848534a83c536019a8011ddde743d64/fda91/healing-house-15-min.webp 3w,\n/static/e848534a83c536019a8011ddde743d64/fadb2/healing-house-15-min.webp 5w,\n/static/e848534a83c536019a8011ddde743d64/bd5c1/healing-house-15-min.webp 10w,\n/static/e848534a83c536019a8011ddde743d64/264f2/healing-house-15-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAEDBAIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAGbsdovc89p1QBcgV//xAAcEAEBAAICAwAAAAAAAAAAAAACAQADERIhIjH/2gAIAQEAAQUCKRr2M4di631fNIbVXzJfKkt//8QAGREBAAIDAAAAAAAAAAAAAAAAAQACEBJR/9oACAEDAQE/ASpNHmCf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQERL/2gAIAQIBAT8Bs0p//8QAHhAAAQMEAwAAAAAAAAAAAAAAAAERIgIQEqEhMWH/2gAIAQEABj8CjolXwJizekHyUfVla3R//8QAGxABAQEBAQADAAAAAAAAAAAAAREAMSFRYXH/2gAIAQEAAT8hoCn3nuIsRDzVntKUXOEPknJobLWvw14Hn7N3wjnnnMtX1v/aAAwDAQACAAMAAAAQs/jB/8QAGhEAAwADAQAAAAAAAAAAAAAAAAERITFhkf/aAAgBAwEBPxBhyU7PC4Rof//EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAECAQE/EBTaLrG3T//EAB8QAQEAAgEEAwAAAAAAAAAAAAERACFBMVFhsYGh0f/aAAgBAQABPxBLoQLD14TNDOBKfCH5kgMkZE5wlmboIrZMuDbINd0x+iAC+rBVdunk36y6kNwNG3j4wTCmrXf3n//Z"
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/31671c22491eb0e54f9d15faa3a06dc2/261e2/healing-house-10-min.jpg",
                      "srcSet": "/static/31671c22491eb0e54f9d15faa3a06dc2/6f45c/healing-house-10-min.jpg 3w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/f8e2e/healing-house-10-min.jpg 5w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/261e2/healing-house-10-min.jpg 10w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/f4935/healing-house-10-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/31671c22491eb0e54f9d15faa3a06dc2/fda91/healing-house-10-min.webp 3w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/fadb2/healing-house-10-min.webp 5w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/bd5c1/healing-house-10-min.webp 10w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/264f2/healing-house-10-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAdU08B81aGcVOAb/xAAaEAACAwEBAAAAAAAAAAAAAAABAgADEhET/9oACAEBAAEFAkoYTLR0bTpqXGtQMGea9CgTpn//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/ATFf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPwE13//EACEQAAECBAcAAAAAAAAAAAAAAAABAhAREjIDISIxcZGi/9oACAEBAAY/AlqdNeC/yLTik2l2o2TqGUP/xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMWFRcYH/2gAIAQEAAT8hao6hSxLYo4jGWnhN533ESDkwA3KNge4RoFfYM0qPmn//2gAMAwEAAgADAAAAEJQnAf/EABcRAQEBAQAAAAAAAAAAAAAAAAARAWH/2gAIAQMBAT8QyOio/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERYf/aAAgBAgEBPxC2uDGv/8QAHRABAQADAAIDAAAAAAAAAAAAAREAITFBUWFxgf/aAAgBAQABPxBHYTiTfW4GyExDjfrWUIK9t+d0wRCsXTjjcvgLZP6eDJpf1ByoI9CDEYVOmCYaHwZ//9k="
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/8b7b96d40a99e8f6ffb82d99aff44c09/261e2/healing-house-11-min.jpg",
                      "srcSet": "/static/8b7b96d40a99e8f6ffb82d99aff44c09/6f45c/healing-house-11-min.jpg 3w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/f8e2e/healing-house-11-min.jpg 5w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/261e2/healing-house-11-min.jpg 10w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/f4935/healing-house-11-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/8b7b96d40a99e8f6ffb82d99aff44c09/fda91/healing-house-11-min.webp 3w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/fadb2/healing-house-11-min.webp 5w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/bd5c1/healing-house-11-min.webp 10w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/264f2/healing-house-11-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAHp5kCygDrElwAv/8QAGhABAQEAAwEAAAAAAAAAAAAAAQACESEiI//aAAgBAQABBQJyTmeB+1q326fMt//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEBH/2gAIAQMBAT8BLjGf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEBH/2gAIAQIBAT8BNRKv/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAIxARARIHH/2gAIAQEABj8CtIrM1McJ1//EABsQAQEAAwADAAAAAAAAAAAAAAEAESExUWFx/9oACAEBAAE/IcwHS+Y3Gy1SLI+HEw9u3TqVBKyZv//aAAwDAQACAAMAAAAQPy+B/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EAwzP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAITH/2gAIAQIBAT8QUmls9v/EABwQAQADAAIDAAAAAAAAAAAAAAEAESExYUFRcf/aAAgBAQABPxDRligO1FjbztmSyPTuGwJr4NjHva8Aaq55ietDSJBwbCWDWA5z/9k="
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/8bbe8a16c13420b157345a6cc06a0817/261e2/healing-house-9-min.jpg",
                      "srcSet": "/static/8bbe8a16c13420b157345a6cc06a0817/6f45c/healing-house-9-min.jpg 3w,\n/static/8bbe8a16c13420b157345a6cc06a0817/f8e2e/healing-house-9-min.jpg 5w,\n/static/8bbe8a16c13420b157345a6cc06a0817/261e2/healing-house-9-min.jpg 10w,\n/static/8bbe8a16c13420b157345a6cc06a0817/f4935/healing-house-9-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/8bbe8a16c13420b157345a6cc06a0817/fda91/healing-house-9-min.webp 3w,\n/static/8bbe8a16c13420b157345a6cc06a0817/fadb2/healing-house-9-min.webp 5w,\n/static/8bbe8a16c13420b157345a6cc06a0817/bd5c1/healing-house-9-min.webp 10w,\n/static/8bbe8a16c13420b157345a6cc06a0817/264f2/healing-house-9-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQH/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB1u0JBZVZZj3AvUDH/8QAGhAAAwADAQAAAAAAAAAAAAAAAAECBBIhMf/aAAgBAQABBQJvhsRUCpOloV5jU6tdP//EABYRAQEBAAAAAAAAAAAAAAAAABABEf/aAAgBAwEBPwHCn//EABYRAQEBAAAAAAAAAAAAAAAAABABEf/aAAgBAgEBPwHSH//EABoQAAIDAQEAAAAAAAAAAAAAAAARARAhMlH/2gAIAQEABj8CvslDiU9rfDT/xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMVFh/9oACAEBAAE/Ia8bcJiO3pItW1pMnDZxvpj8CF78YluO0QuE2sP/2gAMAwEAAgADAAAAEPcQ/P/EABcRAQEBAQAAAAAAAAAAAAAAAAEQIUH/2gAIAQMBAT8QWbOp/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQEUH/2gAIAQIBAT8QN2G4x//EABwQAQEAAwEAAwAAAAAAAAAAAAERACExQWGRof/aAAgBAQABPxDdphAHp84GiesHIelGM4OXKlbIibG9yQg1NCDz8xCAYSbtMFa6EfvNsZ0CemGh02bZn//Z"
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/1b935103b8c3f5f2b4d79333406f8b0b/261e2/healing-house-22-min.jpg",
                      "srcSet": "/static/1b935103b8c3f5f2b4d79333406f8b0b/6f45c/healing-house-22-min.jpg 3w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/f8e2e/healing-house-22-min.jpg 5w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/261e2/healing-house-22-min.jpg 10w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/f4935/healing-house-22-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/1b935103b8c3f5f2b4d79333406f8b0b/fda91/healing-house-22-min.webp 3w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/fadb2/healing-house-22-min.webp 5w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/bd5c1/healing-house-22-min.webp 10w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/264f2/healing-house-22-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQEBAQEAAAAAAAAAAAAAAAACAAED/9oADAMBAAIQAxAAAAHXPFcdxKyPToB0gK//xAAbEAACAwADAAAAAAAAAAAAAAABAgADEhEhIv/aAAgBAQABBQKluENjmNYxKaStLho+oRB3CZ//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAS/9oACAEDAQE/ATSv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEv/aAAgBAgEBPwEy7//EAB4QAAEEAQUAAAAAAAAAAAAAAAABAhAhESJCYXGB/9oACAEBAAY/AuDQ1E7Le/wVt0VuUzGY/8QAHRABAAMAAQUAAAAAAAAAAAAAAQARIWExQVFxwf/aAAgBAQABPyHcqxH80y38loe8AIbUnhjE5K6HjIMWOkuahKo3iOPZ9hP/2gAMAwEAAgADAAAAEFMYP//EABgRAAIDAAAAAAAAAAAAAAAAAAERABBh/9oACAEDAQE/EFkICr//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAECAQE/EBQlwxe//8QAHhABAAMAAgIDAAAAAAAAAAAAAQARITFBYYGRwdH/2gAIAQEAAT8QRuxo414I3O8GRfD7jFF/AGxSvbHg3dETgkuozSHil0EJLJS0nMWBN7hxlH9jn5qf/9k="
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/95d45683e387d48e5c5d1dcaf5aa59c2/261e2/healing-house-8-min.jpg",
                      "srcSet": "/static/95d45683e387d48e5c5d1dcaf5aa59c2/6f45c/healing-house-8-min.jpg 3w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/f8e2e/healing-house-8-min.jpg 5w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/261e2/healing-house-8-min.jpg 10w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/f4935/healing-house-8-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/95d45683e387d48e5c5d1dcaf5aa59c2/fda91/healing-house-8-min.webp 3w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/fadb2/healing-house-8-min.webp 5w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/bd5c1/healing-house-8-min.webp 10w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/264f2/healing-house-8-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAMEBQIG/8QAFwEAAwEAAAAAAAAAAAAAAAAAAQIDAP/aAAwDAQACEAMQAAABm7z7qtRUw3qIwNnCNf/EABsQAQADAAMBAAAAAAAAAAAAAAECAxEAEhMj/9oACAEBAAEFAmX0QyUnafS1nW9dzmG3gQlXFf/EABcRAQADAAAAAAAAAAAAAAAAAAAQEUH/2gAIAQMBAT8BU2P/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEUH/2gAIAQIBAT8BViv/xAAcEAACAQUBAAAAAAAAAAAAAAAAASECEBESMSL/2gAIAQEABj8C1kc8IeDZlXpyieiMq3//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMVH/2gAIAQEAAT8h2bUsqb6SQxpTwRxcnHwrkh9C8XAlQ9GJG1IunT//2gAMAwEAAgADAAAAEMP/AD7/xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxAEN2U8SF//xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhMf/aAAgBAgEBPxBReWLO4We3/8QAHxABAAMAAgEFAAAAAAAAAAAAAQARITFRQWFxgbHB/9oACAEBAAE/EAFicBrPnPqGYUvZIp6EP2LRuGoD36viFqBG2rd6S07inOYyCuhxCemx7uyg1q8M/9k="
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/6fd8333c9cf25a3a672484c424527be2/261e2/healing-house-21-min.jpg",
                      "srcSet": "/static/6fd8333c9cf25a3a672484c424527be2/6f45c/healing-house-21-min.jpg 3w,\n/static/6fd8333c9cf25a3a672484c424527be2/f8e2e/healing-house-21-min.jpg 5w,\n/static/6fd8333c9cf25a3a672484c424527be2/261e2/healing-house-21-min.jpg 10w,\n/static/6fd8333c9cf25a3a672484c424527be2/f4935/healing-house-21-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/6fd8333c9cf25a3a672484c424527be2/fda91/healing-house-21-min.webp 3w,\n/static/6fd8333c9cf25a3a672484c424527be2/fadb2/healing-house-21-min.webp 5w,\n/static/6fd8333c9cf25a3a672484c424527be2/bd5c1/healing-house-21-min.webp 10w,\n/static/6fd8333c9cf25a3a672484c424527be2/264f2/healing-house-21-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIEBQP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQIE/9oADAMBAAIQAxAAAAFfzbOd5TRW+sAqAR//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIRAxITBP/aAAgBAQABBQLz5Oc801Uk9lCDN6OqG6NmUj//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BI//EABgRAAIDAAAAAAAAAAAAAAAAAAARAQIQ/9oACAECAQE/AYuh7//EABsQAAICAwEAAAAAAAAAAAAAAAAhATEQESIy/9oACAEBAAY/AnUnLKPOzl6HECxR/8QAHRAAAgMAAgMAAAAAAAAAAAAAAAERITFhcUGBwf/aAAgBAQABPyFvQiK810vou2fRbSRbeGSSHB0JlsvkbCiJlZHkOj//2gAMAwEAAgADAAAAED/PQ//EABgRAAIDAAAAAAAAAAAAAAAAAAAhARAR/9oACAEDAQE/EENWQf/EABgRAQEAAwAAAAAAAAAAAAAAABEBABBB/9oACAECAQE/ECBcj5v/xAAeEAEBAAMAAgMBAAAAAAAAAAABEQAhMVFxQWGhsf/aAAgBAQABPxAKs6r76YoqRgOLvdcwcJeBv7hSHNQp9tZrRMb0e1wH4MtWGjma2h4JgoKj0cWMK3Eh/c//2Q=="
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/c243d097e6eaf8b3774d966a23f4c6c7/261e2/healing-house-6-min.jpg",
                      "srcSet": "/static/c243d097e6eaf8b3774d966a23f4c6c7/6f45c/healing-house-6-min.jpg 3w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/f8e2e/healing-house-6-min.jpg 5w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/261e2/healing-house-6-min.jpg 10w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/f4935/healing-house-6-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/c243d097e6eaf8b3774d966a23f4c6c7/fda91/healing-house-6-min.webp 3w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/fadb2/healing-house-6-min.webp 5w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/bd5c1/healing-house-6-min.webp 10w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/264f2/healing-house-6-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUCBP/EABcBAQADAAAAAAAAAAAAAAAAAAIAAQP/2gAMAwEAAhADEAAAAddEG5nch2AtVSLAZ//EABsQAAICAwEAAAAAAAAAAAAAAAECAAQDESEj/9oACAEBAAEFAktODbfz7NxO13xabCg2IUWf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwFia//EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAECAQE/ASH/xAAZEAACAwEAAAAAAAAAAAAAAAABAgAQMSH/2gAIAQEABj8CBboMUrhsIdnLyf/EABoQAQEBAQEBAQAAAAAAAAAAAAEAESExQVH/2gAIAQEAAT8hTE+CZ6nbYizj6wMqh/YstZe8bcEh1O3/2gAMAwEAAgADAAAAEGDv/P/EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAIGT//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAECAQE/EBYjmcw3/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARITFBUWHB4f/aAAgBAQABPxDXO1Cs9MqQc4d1n2BChy+SDiONVZ+TR526N6jMAtBRSItYOFrITHKi9Bas/9k="
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/cfec6bde048b4e46d5983f2391614ff2/261e2/healing-house-min.jpg",
                      "srcSet": "/static/cfec6bde048b4e46d5983f2391614ff2/6f45c/healing-house-min.jpg 3w,\n/static/cfec6bde048b4e46d5983f2391614ff2/f8e2e/healing-house-min.jpg 5w,\n/static/cfec6bde048b4e46d5983f2391614ff2/261e2/healing-house-min.jpg 10w,\n/static/cfec6bde048b4e46d5983f2391614ff2/f4935/healing-house-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/cfec6bde048b4e46d5983f2391614ff2/fda91/healing-house-min.webp 3w,\n/static/cfec6bde048b4e46d5983f2391614ff2/fadb2/healing-house-min.webp 5w,\n/static/cfec6bde048b4e46d5983f2391614ff2/bd5c1/healing-house-min.webp 10w,\n/static/cfec6bde048b4e46d5983f2391614ff2/264f2/healing-house-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          },
          {
            "localFile": {
              "childImageSharp": {
                "gatsbyImageData": {
                  "layout": "constrained",
                  "placeholder": {
                    "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAMBAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQD/2gAMAwEAAhADEAAAAaVmNu5jM9A3Au//xAAcEAACAgIDAAAAAAAAAAAAAAABAgADERITISL/2gAIAQEAAQUCUsr2k1qPUcqsS3ebM0K5OnfHmf/EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAEDAQE/AWP/xAAYEQACAwAAAAAAAAAAAAAAAAABEQACEP/aAAgBAgEBPwGpYce//8QAHhAAAgMAAQUAAAAAAAAAAAAAAAECESExEiIyQWH/2gAIAQEABj8C7+Pgmk5WeLM9GoxUbKRfVKzln//EABwQAQEBAAMAAwAAAAAAAAAAAAERADFBUSFxgf/aAAgBAQABPyFC9PCuqFHHm7jLPJhfnEiz3i6QGS8Zyavbif0FyErf73//2gAMAwEAAgADAAAAEAsYwP/EABcRAQEBAQAAAAAAAAAAAAAAABEBABD/2gAIAQMBAT8QkIYvf//EABkRAAIDAQAAAAAAAAAAAAAAAAABEBExQf/aAAgBAgEBPxDUFBLkf//EAB8QAQEAAgEEAwAAAAAAAAAAAAERACExQVGBkWGx8P/aAAgBAQABPxCki2Fg7P64H03i6zjziQiAxFm4PnnDcpud4nHvKOqJIOhjymnHGoKg06YwUeIU79MGT5kL9YtcmrBfRn//2Q=="
                  },
                  "images": {
                    "fallback": {
                      "src": "/static/3963305c6ef8a28cc56e2b09d99d4a86/261e2/healing-house-12-min.jpg",
                      "srcSet": "/static/3963305c6ef8a28cc56e2b09d99d4a86/6f45c/healing-house-12-min.jpg 3w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/f8e2e/healing-house-12-min.jpg 5w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/261e2/healing-house-12-min.jpg 10w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/f4935/healing-house-12-min.jpg 20w",
                      "sizes": "(min-width: 10px) 10px, 100vw"
                    },
                    "sources": [
                      {
                        "srcSet": "/static/3963305c6ef8a28cc56e2b09d99d4a86/fda91/healing-house-12-min.webp 3w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/fadb2/healing-house-12-min.webp 5w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/bd5c1/healing-house-12-min.webp 10w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/264f2/healing-house-12-min.webp 20w",
                        "type": "image/webp",
                        "sizes": "(min-width: 10px) 10px, 100vw"
                      }
                    ]
                  },
                  "width": 10,
                  "height": 10
                }
              }
            }
          }
        ]
      }
    }
  },
  {
    "id": "cG9zdDoxXTIyNw==",
    "title": "Energy Boost Mini Class",
    "uri": "/events/quantum-touch",
    "eventType": "qt-energy-boost",
    "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
    "datestimes": '',
    "eventsMetaData": {
      "datetime": "2024-07-17_19:30:00",
      "datetimeend": "2024-07-17_21:30:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
      "location": "Zoom",
      "lastspaces": true,
      "lastfewdaysearlybird": null,
      "workshopName": "Quantum-Touch Energy Boost",
      "eventpassed": null,
      "price": 45,
      "priceSale": null,
      "postalcode": null,
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
    }
  },
  {
    "id": "bQ4zcDoxMTeyPs==",
    "title": "Quantum-Touch Level 1",
    "excerpt": "This LIVE in person Quantum-Touch Level 1 workshop in London spread over 2 days",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-1",
    "datestimes": '<ul><li>Monday 16th September - 09:30 – 17:30 BST</li><li>Tuesday 17th September - 09:30 – 17:30 BST</li></ul>',
    "eventsMetaData": {
      "datetime": "2024-09-16_09:30:00",
      "datetimeend": "2024-09-17_17:30:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-1-september-in-london",
      "location": "Healing House",
      "lastspaces": "Last 2 spaces available ",
      "lastfewdaysearlybird": null,
      "eventpassed": null,
      "price": 320,
      "priceSale": 265,
      "postalcode": "N22 8DH",
      "addressregion": "London",
      "addresslocality": "Bounds Green",
      "addresscountry": "GB",
      "workshopName": "Quantum-Touch Level 1",
      "stripeUrl": 'https://buy.stripe.com/fZe4gleLJ2oE5TW8BF',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/4gw005eLJ8N2cik7ym',
      "stripeUrlDeposit": 'https://buy.stripe.com/aEU3ch9rpaVa3LOaJT',
      "stripeUrlRepeat": 'https://buy.stripe.com/28ofZ39rpe7mcikaJU',
      "lunch": true
    },
  },
  {
    "id": "cG9zdXoxMTIyNw==",
    "title": "Energy Boost Mini Class",
    "uri": "/events/quantum-touch",
    "eventType": "qt-energy-boost",
    "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
    "datestimes": '',
    "eventsMetaData": {
      "datetime": "2024-09-30_19:30:00",
      "datetimeend": "2024-09-30_21:30:00",
      "timezone": "GMT",
      "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
      "location": "Zoom",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "workshopName": "Quantum-Touch Energy Boost",
      "eventpassed": null,
      "price": 45,
      "priceSale": null,
      "postalcode": null,
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
    }
  },
  {
    "id": "cG9zdDoxMTIzMw==",
    "title": "Quantum-Touch Level 1",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-1",
    "excerpt": "This LIVE online Quantum-Touch Level 1 workshop starts on Tuesday 8th October and spread over 4 evenings",
    "datestimes": '<ul><li>Tuesday 8th October 18:00 – 21:15 BST</li><li>Wednesday 9th October 18:00 – 21:15 BST</li><li>Monday 14th October 18:00 – 21:15 BST</li><li>Tuesday 15th October 18:00 – 21:15 BST</li></ul>',
    "eventsMetaData": {
      "datetime": "2024-10-08_18:00:00",
      "datetimeend": "2024-10-15_21:15:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-1-energy-healing-october-20204",
      "location": "Zoom",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "eventpassed": null,
      "price": 320,
      "priceSale": 265,
      "postalcode": null,
      "addresscountry": "GB",
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": null,
      "workshopName": "Quantum-Touch Level 1",
      "stripeUrl": 'https://buy.stripe.com/fZe4gleLJ2oE5TW8BF',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/4gw005eLJ8N2cik7ym',
      "stripeUrlDeposit": 'https://buy.stripe.com/aEU3ch9rpaVa3LOaJT',
      "stripeUrlRepeat": 'https://buy.stripe.com/28ofZ39rpe7mcikaJU',
    }
  },
  {
    "id": "vG9zdQoxMTIcMw==",
    "title": "Becoming More with Richard Gordon",
    "uri": "/events/quantum-touch",
    "eventType": "qt-becoming-more",
    "excerpt": "Don't miss out on this extraordinary opportunity to connect with like-minded individuals and tap into new dimensions of love, joy, and gratitude.",
    "datestimes": '<ul><li>Saturday 19th October 10:00 – 17:00 EST</li><li>Sunday 20th October 10:00 – 17:00 EST</li></ul>',
    "eventsMetaData": {
      "datetime": "2024-10-19_10:00:00",
      "datetimeend": "2024-10-20_17:00:00",
      "timezone": "EST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-1-energy-healing-october-20204",
      "location": "Occidental Costa Cancún",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "eventpassed": null,
      "price": 650,
      "currency": "USD",
      "priceSale": null,
      "postalcode": null,
      "addresscountry": "Mexico",
      "addresslocality": "Cancun",
      "addressregion": null,
      "locationdescription": null,
      "workshopName": "Quantum-Touch Becoming More",
      "stripeUrl": 'https://quantumtouch.com/en/workshops/details/31204?partner_id=25511',
    }
  },
  // {
  //   "id": "hV9zcDoxMTeyOI==",
  //   "title": "Quantum-Touch Self Created Health",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-self-created-health",
  //   "excerpt": "This Quantum-Touch Self Created Health workshop Live online over 2 days.",
  //   "datestimes": '<ul><li>Monday 28th October 2024 - 09:30 – 17:30</li><li>Tuesday 29th October - 2024 09:30 – 17:30</li></ul>',
  //   "eventsMetaData": {
  //     "datetime": "2024-10-28_09:30:00",
  //     "datetimeend": null,
  //     "timezone": "BST",
  //     "locationUrl": null,
  //     "location": "Zoom",
  //     "lastspaces": null,
  //     "lastfewdaysearlybird": null,
  //     "eventpassed": null,
  //     "price": 399,
  //     "priceSale": 349,
  //     "postalcode": null,
  //     "addresscountry": "GB",
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "locationdescription": null,
  //     "workshopName": "Quantum-Touch Self Created Health",
  //     "usPrice": 690,
  //     "stripeUrl": 'https://buy.stripe.com/bIYaEJ9rp4wM968aJZ',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/5kAcMRcDB1kAaaccSa',
  //     "stripeUrlDeposit": 'https://buy.stripe.com/dR64glgTR3sI8244lC',
  //     "stripeUrlRepeat": 'https://buy.stripe.com/3csdQV3316EU6Y09FX',
  //     "lunch": false
  //   }
  // },
  {
    "id": "DG9zdDoxMTIyNw==",
    "title": "Energy Boost Mini Class",
    "uri": "/events/quantum-touch",
    "eventType": "qt-energy-boost",
    "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
    "datestimes": '',
    "eventsMetaData": {
      "datetime": "2024-11-11_19:30:00",
      "datetimeend": "2024-11-11_21:30:00",
      "timezone": "GMT",
      "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
      "location": "Zoom",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "workshopName": "Quantum-Touch Energy Boost",
      "eventpassed": null,
      "price": 45,
      "priceSale": null,
      "postalcode": null,
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
    }
  },
  // {
  //   "id": "xF9zcDoxMTFyOQ==",
  //   "title": "Quantum-Touch Level 2",
  //   "excerpt": "This Quantum-Touch Level 2 workshop is online and spread over 4 sessions, each session is just over 3 hours long.",
  //   "uri": "/events/quantum-touch",
  //   "date": "2024-02-13T17:03:29",
  //   "eventType": "qt-level-2",
  //   "datestimes": '<ul><li>Tuesday 3rd December 18:00 – 21:15 GMT</li><li>Wednesday 4th December 18:00 – 21:15 GMT</li><li>Tuesday 10th December 18:00 – 21:15 GMT</li><li>Wednesday 11th December 18:00 – 21:15 GMT</li></ul>',
  //   "eventsMetaData": {
  //     "addresscountry": null,
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "datetime": "2024-12-03_18:00:00",
  //     "datetimeend": "2024-12-11_21:15:00",
  //     "timezone": "GMT",
  //     "eventdatechange": null,
  //     "eventpassed": null,
  //     "fieldGroupName": "eventsMetaData",
  //     "lastfewdaysearlybird": null,
  //     "lastspaces": null,
  //     "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-dec-online/",
  //     "location": "Zoom",
  //     "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
  //     "onlineandlive": null,
  //     "postalcode": null,
  //     "price": 399,
  //     "priceSale": 349,
  //     "stripeUrl": 'https://buy.stripe.com/28o5kpeLJd3i4PS19m',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/3cs6ot9rp9R60zC7xJ',
  //     "stripeUrlDeposit": 'https://buy.stripe.com/9AQ0058nlaVacik8BP',
  //     "stripeUrlRepeat": 'https://buy.stripe.com/bIYdQV0UT9R63LO2ds',
  //     "workshopName": "Quantum-Touch Level 2"
  //   }
  // },
  {
    "id": "xF9zcDoxMTFyOM==",
    "title": "Quantum-Touch Manifesting Miracles",
    "excerpt": "This Quantum-Touch Manifesting Miracles workshop is online and spread over 4 sessions, each session is just over 3 hours long.",
    "uri": "/events/quantum-touch",
    "date": "2025-01-06T18:00:00",
    "eventType": "qt-becoming-miraculous",
    "datestimes": '<ul><li>Tuesday 7th January 14:00 – 17:15 GMT</li><li>Wednesday 8th January 14:00 – 17:15 GMT</li><li>Wednesday 15th January 14:00 – 17:15 GMT</li><li>Thursday 16th January 14:00 – 17:15 GMT</li></ul>',
    "eventsMetaData": {
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "datetime": "2025-01-07_14:00:00",
      "datetimeend": "2025-01-07_17:15:00",
      "timezone": "GMT",
      "eventdatechange": null,
      "eventpassed": null,
      "fieldGroupName": "eventsMetaData",
      "lastfewdaysearlybird": null,
      "lastspaces": null,
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-dec-online/",
      "location": "Zoom",
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "onlineandlive": null,
      "postalcode": null,
      "price": 399,
      "priceSale": 349,
      "stripeUrl": 'https://buy.stripe.com/eVa3ch1YXd3i1DG8Cn',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/cN2cMR5b9aVagyAcSC',
      "stripeUrlDeposit": 'https://buy.stripe.com/14k7sxavt9R6aac05M',
      "stripeUrlRepeat": 'https://buy.stripe.com/5kAcMRdHFe7m0zCf1f',
      "workshopName": "Quantum-Touch Manifesting Miracles"
    }
  },
  // {
  //   "id": "zG9zdDoxMTIyNw==",
  //   "title": "Energy Boost Mini Class",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-energy-boost",
  //   "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
  //   "datestimes": '',
  //   "eventsMetaData": {
  //     "datetime": "2025-01-22_19:30:00",
  //     "datetimeend": "2025-01-22_21:30:00",
  //     "timezone": "GMT",
  //     "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
  //     "location": "Zoom",
  //     "lastspaces": null,
  //     "lastfewdaysearlybird": null,
  //     "workshopName": "Quantum-Touch Energy Boost",
  //     "eventpassed": null,
  //     "price": 45,
  //     "priceSale": null,
  //     "postalcode": null,
  //     "addresscountry": null,
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
  //     "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //   }
  // },
  {
    "id": "cG9zdCoxMDc0OA==",
    "title": "Quantum-Touch Level 1",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-1",
    "excerpt": "This LIVE Quantum-Touch Level 1 workshop is online and spread over four 3-hour sessions.",
    "datestimes": '<ul><li>Tuesday 4th February at 14:00 – 17:15 GMT</li><li>Thursday 6th February at 14:00 – 17:15 GMT</li><li>Tuesday 11th February at 14:00 – 17:15 GMT</li><li>Thursday 13th February at 14:00 – 17:15 GMT</li></ul>',
    "eventsMetaData": {
      "datetime": "2025-02-04_14:00:00",
      "datetimeend": "2025-02-04_17:15:00",
      "timezone": "GMT",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-1-june-online",
      "location": "Zoom",
      "lastspaces": "Last spaces available",
      "lastfewdaysearlybird": null,
      "eventpassed": null,
      "price": 320,
      "priceSale": 265,
      "postalcode": null,
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "workshopName": "Quantum-Touch Level 1",
      "stripeUrl": 'https://buy.stripe.com/fZe4gleLJ2oE5TW8BF',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/4gw005eLJ8N2cik7ym',
      "stripeUrlDeposit": 'https://buy.stripe.com/aEU3ch9rpaVa3LOaJT',
      "stripeUrlRepeat": 'https://buy.stripe.com/28ofZ39rpe7mcikaJU',
    }
  },

  {
    "id": "xF9zcDcQ3TFyOM==",
    "title": "Quantum-Touch Manifesting Miracles",
    "excerpt": "This Quantum-Touch Manifesting Miracles workshop is online and spread over 4 sessions, each session is just over 3 hours long.",
    "uri": "/events/quantum-touch",
    "eventType": "qt-becoming-miraculous",
    "datestimes": '<ul><li>Tuesday 25th February</li><li>Wednesday 26th February</li><li>Tuesday 4th March</li><li>Wednesday 5th March</li></ul><p>The workshop is scheduled to take place from 14:30 to 17:30 GMT on all the specified dates.</p>',
    "eventsMetaData": {
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "datetime": "2025-02-25_14:30:00",
      "datetimeend": "2025-03-05_17:30:00",
      "timezone": "GMT",
      "eventdatechange": null,
      "eventpassed": null,
      "fieldGroupName": "eventsMetaData",
      "lastspaces": true,
      "lastfewdaysearlybird": "Last chance to book",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-dec-online/",
      "location": "Zoom",
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "onlineandlive": null,
      "postalcode": null,
      "price": 399,
      "priceSale": 349,
      "stripeUrl": 'https://buy.stripe.com/eVa3ch1YXd3i1DG8Cn',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/cN2cMR5b9aVagyAcSC',
      "stripeUrlDeposit": 'https://buy.stripe.com/14k7sxavt9R6aac05M',
      "stripeUrlRepeat": 'https://buy.stripe.com/5kAcMRdHFe7m0zCf1f',
      "workshopName": "Quantum-Touch Manifesting Miracles"
    }
  },

  // {
  //   "id": "cG9zdDoxxMDc2NQ==",
  //   "title": "Quantum-Touch Supercharging",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-supercharging",
  //   "excerpt": "This LIVE Quantum-Touch Supercharging workshop is online and spread over four 3-hour sessions.",
  //   "datestimes": '<ul>\n<li>Tuesday 24th June 14:00 – 17:15</li><li>Wednesday 25th June - 14:00 – 17:15</li><li>Tuesday 1st July - 14:00 – 17:15</li><li>Wednesday 2nd July - 14:00 – 17:15</li></ul>',
  //   "eventsMetaData": {
  //     "datetime": "2025-06-24_14:00:00",
  //     "datetimeend": "2025-07-02_17:15:00",
  //     "timezone": "GMT",
  //     "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-march-in-london/",
  //     "location": "Zoom",
  //     "lastspaces": null,
  //     "lastfewdaysearlybird": null,
  //     "eventpassed": null,
  //     "price": 399,
  //     "priceSale": 349,
  //     "postalcode": "N22 8DH",
  //     "addresscountry": "GB",
  //     "addresslocality": "Bounds Green",
  //     "addressregion": "London",
  //     "locationdescription": "A hidden oasis in London! Originally the gardener’s cottage for a Victorian market garden, now a beautifully renovated house for events and workshops.",
  //     "workshopName": "Quantum-Touch Supercharging",
  //     "stripeUrl": 'https://buy.stripe.com/6oEbIN0UT4wMbeg7xU',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/cN24gleLJbZe3LOdWh',
  //     "stripeUrlDeposit": 'https://buy.stripe.com/fZedQVcDB2oEeqs19t',
  //     "stripeUrlRepeat": 'https://buy.stripe.com/7sI005fPN0gwgyA2dy',
  //     "lunch": true,
  //     "upload_gallery": {
  //       "venueGallery": [
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBP/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdOYd2lDrM0zAP/EABwQAAEEAwEAAAAAAAAAAAAAABEAAQIDBBAhE//aAAgBAQABBQK/qlIvj2Q8xwBhouV//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAIQEf/aAAgBAwEBPwFqVf/EABgRAAIDAAAAAAAAAAAAAAAAAAABAhAR/9oACAECAQE/AWYRr//EABsQAAICAwEAAAAAAAAAAAAAAAARECICMUFC/9oACAEBAAY/Am0hvyXyscjgzcf/xAAcEAACAwEAAwAAAAAAAAAAAAABEQAhMVFBYYH/2gAIAQEAAT8hQAX2QajJQV5gV6xdZCC5I2pjAIWwdAvbiFoH2ESMo5//2gAMAwEAAgADAAAAENcvPf/EABkRAQEBAAMAAAAAAAAAAAAAAAEAESExUf/aAAgBAwEBPxA4MWB7dYDL/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERIWH/2gAIAQIBAT8QTS+mmNun/8QAHRABAAMAAwADAAAAAAAAAAAAAQARITFBkWFx8f/aAAgBAQABPxAGFDOF+RCisG3JXPIM3mD5cQgHRHC/bpBFQVBt/YiWH1hnyVFxmNAiVXQ0Mya1J//Z"
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/9a971bc2f452343aad203bf923932692/261e2/healing-house-14-min.jpg",
  //                     "srcSet": "/static/9a971bc2f452343aad203bf923932692/6f45c/healing-house-14-min.jpg 3w,\n/static/9a971bc2f452343aad203bf923932692/f8e2e/healing-house-14-min.jpg 5w,\n/static/9a971bc2f452343aad203bf923932692/261e2/healing-house-14-min.jpg 10w,\n/static/9a971bc2f452343aad203bf923932692/f4935/healing-house-14-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/9a971bc2f452343aad203bf923932692/fda91/healing-house-14-min.webp 3w,\n/static/9a971bc2f452343aad203bf923932692/fadb2/healing-house-14-min.webp 5w,\n/static/9a971bc2f452343aad203bf923932692/bd5c1/healing-house-14-min.webp 10w,\n/static/9a971bc2f452343aad203bf923932692/264f2/healing-house-14-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUBBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIBAP/aAAwDAQACEAMQAAABM4iqzCMAS0Rp/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAAxASEyEi/9oACAEBAAEFAjXQCrq04jLmrE3WK/WEPn//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/ATXf/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/ASP/xAAdEAEAAgAHAAAAAAAAAAAAAAABABECEiAhMTKR/9oACAEBAAY/AuGZBSd8fsKqGxp//8QAGhABAQEBAQEBAAAAAAAAAAAAAQARMUFR0f/aAAgBAQABPyFF2OIv7Zwdb7QHA3vbxA+obBnFbIsyYX//2gAMAwEAAgADAAAAEEAgf//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBFh/9oACAEDAQE/EFSNBR//xAAYEQEAAwEAAAAAAAAAAAAAAAABABARUf/aAAgBAgEBPxB1ZnEa/8QAHhABAQEAAgEFAAAAAAAAAAAAAREAITGBQVFhcaH/2gAIAQEAAT8Q4yE6Iv3hjeC28/K1n5kXwkoTxM8PQQ064982DzgGMyYBKveCrxiLnsUfW7//2Q=="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/8e8a72d777dd58627a29f986024cbae4/261e2/healing-house-13-min.jpg",
  //                     "srcSet": "/static/8e8a72d777dd58627a29f986024cbae4/6f45c/healing-house-13-min.jpg 3w,\n/static/8e8a72d777dd58627a29f986024cbae4/f8e2e/healing-house-13-min.jpg 5w,\n/static/8e8a72d777dd58627a29f986024cbae4/261e2/healing-house-13-min.jpg 10w,\n/static/8e8a72d777dd58627a29f986024cbae4/f4935/healing-house-13-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/8e8a72d777dd58627a29f986024cbae4/fda91/healing-house-13-min.webp 3w,\n/static/8e8a72d777dd58627a29f986024cbae4/fadb2/healing-house-13-min.webp 5w,\n/static/8e8a72d777dd58627a29f986024cbae4/bd5c1/healing-house-13-min.webp 10w,\n/static/8e8a72d777dd58627a29f986024cbae4/264f2/healing-house-13-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQADAQAAAAAAAAAAAAAAAAABAgQD/9oADAMBAAIQAxAAAAHXRGclNDWpx4oZJgj/xAAbEAADAQEAAwAAAAAAAAAAAAAAAQISAxETIv/aAAgBAQABBQKfgvFNonpDrXPwvUzCMSYk/8QAFREBAQAAAAAAAAAAAAAAAAAAICL/2gAIAQMBAT8Bkf/EABYRAQEBAAAAAAAAAAAAAAAAAAECIP/aAAgBAgEBPwFq1x//xAAeEAACAQMFAAAAAAAAAAAAAAAAAQIREiEQMUFxgf/aAAgBAQAGPwI57qYm2VvQ1dF+mHHXY//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExUWFB/9oACAEBAAE/Ic223PSk6t1pBB5neDBfe2O0Y7+iGwja+Dbg8J//2gAMAwEAAgADAAAAEOMPff/EABkRAQACAwAAAAAAAAAAAAAAAAEAERAhMf/aAAgBAwEBPxCgWIsTfc//xAAYEQEAAwEAAAAAAAAAAAAAAAABABARIf/aAAgBAgEBPxDpByDhf//EAB4QAAICAgMBAQAAAAAAAAAAAAEhABExQXGRwVGx/9oACAEBAAE/EAZdkBb1kqAHDsCh4KPUX6TYPJdTUKQL4LmRGVQTshD+fTofsaC7gFR+YKPGf//Z"
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/9f421a75e802b60b3b4da42665de5a7c/261e2/healing-house-19-min.jpg",
  //                     "srcSet": "/static/9f421a75e802b60b3b4da42665de5a7c/6f45c/healing-house-19-min.jpg 3w,\n/static/9f421a75e802b60b3b4da42665de5a7c/f8e2e/healing-house-19-min.jpg 5w,\n/static/9f421a75e802b60b3b4da42665de5a7c/261e2/healing-house-19-min.jpg 10w,\n/static/9f421a75e802b60b3b4da42665de5a7c/f4935/healing-house-19-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/9f421a75e802b60b3b4da42665de5a7c/fda91/healing-house-19-min.webp 3w,\n/static/9f421a75e802b60b3b4da42665de5a7c/fadb2/healing-house-19-min.webp 5w,\n/static/9f421a75e802b60b3b4da42665de5a7c/bd5c1/healing-house-19-min.webp 10w,\n/static/9f421a75e802b60b3b4da42665de5a7c/264f2/healing-house-19-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAMCAP/aAAwDAQACEAMQAAABtDg0CSa6zwOk1agK/wD/xAAbEAADAQADAQAAAAAAAAAAAAABAgMAERITQf/aAAgBAQABBQL37YczD0cNKum5ZjIsfooy5aEj/8QAFhEAAwAAAAAAAAAAAAAAAAAAAREg/9oACAEDAQE/AQo//8QAGREAAgMBAAAAAAAAAAAAAAAAAAECEBFR/9oACAECAQE/AZN8NjWI/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQACESEQIjFBgf/aAAgBAQAGPwI1SJb2rEKxJWrD6p0wHDnH/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERITFBUZH/2gAIAQEAAT8htZnij5W24ycQ0IN/Ug9KiV2oPrRfFeDzHssTgt3D/9oADAMBAAIAAwAAABD7H4P/xAAYEQEBAQEBAAAAAAAAAAAAAAABEQAQQf/aAAgBAwEBPxAzbHIrvef/xAAYEQEBAQEBAAAAAAAAAAAAAAABABExYf/aAAgBAgEBPxALhogjNnl5X//EABwQAQACAwEBAQAAAAAAAAAAAAEAESFRYTFx8P/aAAgBAQABPxAH0r7ZuU00eUK3+3MaNKNt9gOwNYyd0p2M2WJSHW/ke9F4pGpdVImCRmnWWm4Fznk//9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/e848534a83c536019a8011ddde743d64/261e2/healing-house-15-min.jpg",
  //                     "srcSet": "/static/e848534a83c536019a8011ddde743d64/6f45c/healing-house-15-min.jpg 3w,\n/static/e848534a83c536019a8011ddde743d64/f8e2e/healing-house-15-min.jpg 5w,\n/static/e848534a83c536019a8011ddde743d64/261e2/healing-house-15-min.jpg 10w,\n/static/e848534a83c536019a8011ddde743d64/f4935/healing-house-15-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/e848534a83c536019a8011ddde743d64/fda91/healing-house-15-min.webp 3w,\n/static/e848534a83c536019a8011ddde743d64/fadb2/healing-house-15-min.webp 5w,\n/static/e848534a83c536019a8011ddde743d64/bd5c1/healing-house-15-min.webp 10w,\n/static/e848534a83c536019a8011ddde743d64/264f2/healing-house-15-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAEDBAIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAGbsdovc89p1QBcgV//xAAcEAEBAAICAwAAAAAAAAAAAAACAQADERIhIjH/2gAIAQEAAQUCKRr2M4di631fNIbVXzJfKkt//8QAGREBAAIDAAAAAAAAAAAAAAAAAQACEBJR/9oACAEDAQE/ASpNHmCf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQERL/2gAIAQIBAT8Bs0p//8QAHhAAAQMEAwAAAAAAAAAAAAAAAAERIgIQEqEhMWH/2gAIAQEABj8CjolXwJizekHyUfVla3R//8QAGxABAQEBAQADAAAAAAAAAAAAAREAMSFRYXH/2gAIAQEAAT8hoCn3nuIsRDzVntKUXOEPknJobLWvw14Hn7N3wjnnnMtX1v/aAAwDAQACAAMAAAAQs/jB/8QAGhEAAwADAQAAAAAAAAAAAAAAAAERITFhkf/aAAgBAwEBPxBhyU7PC4Rof//EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAECAQE/EBTaLrG3T//EAB8QAQEAAgEEAwAAAAAAAAAAAAERACFBMVFhsYGh0f/aAAgBAQABPxBLoQLD14TNDOBKfCH5kgMkZE5wlmboIrZMuDbINd0x+iAC+rBVdunk36y6kNwNG3j4wTCmrXf3n//Z"
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/31671c22491eb0e54f9d15faa3a06dc2/261e2/healing-house-10-min.jpg",
  //                     "srcSet": "/static/31671c22491eb0e54f9d15faa3a06dc2/6f45c/healing-house-10-min.jpg 3w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/f8e2e/healing-house-10-min.jpg 5w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/261e2/healing-house-10-min.jpg 10w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/f4935/healing-house-10-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/31671c22491eb0e54f9d15faa3a06dc2/fda91/healing-house-10-min.webp 3w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/fadb2/healing-house-10-min.webp 5w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/bd5c1/healing-house-10-min.webp 10w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/264f2/healing-house-10-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAdU08B81aGcVOAb/xAAaEAACAwEBAAAAAAAAAAAAAAABAgADEhET/9oACAEBAAEFAkoYTLR0bTpqXGtQMGea9CgTpn//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/ATFf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPwE13//EACEQAAECBAcAAAAAAAAAAAAAAAABAhAREjIDISIxcZGi/9oACAEBAAY/AlqdNeC/yLTik2l2o2TqGUP/xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMWFRcYH/2gAIAQEAAT8hao6hSxLYo4jGWnhN533ESDkwA3KNge4RoFfYM0qPmn//2gAMAwEAAgADAAAAEJQnAf/EABcRAQEBAQAAAAAAAAAAAAAAAAARAWH/2gAIAQMBAT8QyOio/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERYf/aAAgBAgEBPxC2uDGv/8QAHRABAQADAAIDAAAAAAAAAAAAAREAITFBUWFxgf/aAAgBAQABPxBHYTiTfW4GyExDjfrWUIK9t+d0wRCsXTjjcvgLZP6eDJpf1ByoI9CDEYVOmCYaHwZ//9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/8b7b96d40a99e8f6ffb82d99aff44c09/261e2/healing-house-11-min.jpg",
  //                     "srcSet": "/static/8b7b96d40a99e8f6ffb82d99aff44c09/6f45c/healing-house-11-min.jpg 3w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/f8e2e/healing-house-11-min.jpg 5w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/261e2/healing-house-11-min.jpg 10w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/f4935/healing-house-11-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/8b7b96d40a99e8f6ffb82d99aff44c09/fda91/healing-house-11-min.webp 3w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/fadb2/healing-house-11-min.webp 5w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/bd5c1/healing-house-11-min.webp 10w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/264f2/healing-house-11-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAHp5kCygDrElwAv/8QAGhABAQEAAwEAAAAAAAAAAAAAAQACESEiI//aAAgBAQABBQJyTmeB+1q326fMt//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEBH/2gAIAQMBAT8BLjGf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEBH/2gAIAQIBAT8BNRKv/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAIxARARIHH/2gAIAQEABj8CtIrM1McJ1//EABsQAQEAAwADAAAAAAAAAAAAAAEAESExUWFx/9oACAEBAAE/IcwHS+Y3Gy1SLI+HEw9u3TqVBKyZv//aAAwDAQACAAMAAAAQPy+B/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EAwzP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAITH/2gAIAQIBAT8QUmls9v/EABwQAQADAAIDAAAAAAAAAAAAAAEAESExYUFRcf/aAAgBAQABPxDRligO1FjbztmSyPTuGwJr4NjHva8Aaq55ietDSJBwbCWDWA5z/9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/8bbe8a16c13420b157345a6cc06a0817/261e2/healing-house-9-min.jpg",
  //                     "srcSet": "/static/8bbe8a16c13420b157345a6cc06a0817/6f45c/healing-house-9-min.jpg 3w,\n/static/8bbe8a16c13420b157345a6cc06a0817/f8e2e/healing-house-9-min.jpg 5w,\n/static/8bbe8a16c13420b157345a6cc06a0817/261e2/healing-house-9-min.jpg 10w,\n/static/8bbe8a16c13420b157345a6cc06a0817/f4935/healing-house-9-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/8bbe8a16c13420b157345a6cc06a0817/fda91/healing-house-9-min.webp 3w,\n/static/8bbe8a16c13420b157345a6cc06a0817/fadb2/healing-house-9-min.webp 5w,\n/static/8bbe8a16c13420b157345a6cc06a0817/bd5c1/healing-house-9-min.webp 10w,\n/static/8bbe8a16c13420b157345a6cc06a0817/264f2/healing-house-9-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQH/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB1u0JBZVZZj3AvUDH/8QAGhAAAwADAQAAAAAAAAAAAAAAAAECBBIhMf/aAAgBAQABBQJvhsRUCpOloV5jU6tdP//EABYRAQEBAAAAAAAAAAAAAAAAABABEf/aAAgBAwEBPwHCn//EABYRAQEBAAAAAAAAAAAAAAAAABABEf/aAAgBAgEBPwHSH//EABoQAAIDAQEAAAAAAAAAAAAAAAARARAhMlH/2gAIAQEABj8CvslDiU9rfDT/xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMVFh/9oACAEBAAE/Ia8bcJiO3pItW1pMnDZxvpj8CF78YluO0QuE2sP/2gAMAwEAAgADAAAAEPcQ/P/EABcRAQEBAQAAAAAAAAAAAAAAAAEQIUH/2gAIAQMBAT8QWbOp/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQEUH/2gAIAQIBAT8QN2G4x//EABwQAQEAAwEAAwAAAAAAAAAAAAERACExQWGRof/aAAgBAQABPxDdphAHp84GiesHIelGM4OXKlbIibG9yQg1NCDz8xCAYSbtMFa6EfvNsZ0CemGh02bZn//Z"
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/1b935103b8c3f5f2b4d79333406f8b0b/261e2/healing-house-22-min.jpg",
  //                     "srcSet": "/static/1b935103b8c3f5f2b4d79333406f8b0b/6f45c/healing-house-22-min.jpg 3w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/f8e2e/healing-house-22-min.jpg 5w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/261e2/healing-house-22-min.jpg 10w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/f4935/healing-house-22-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/1b935103b8c3f5f2b4d79333406f8b0b/fda91/healing-house-22-min.webp 3w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/fadb2/healing-house-22-min.webp 5w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/bd5c1/healing-house-22-min.webp 10w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/264f2/healing-house-22-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQEBAQEAAAAAAAAAAAAAAAACAAED/9oADAMBAAIQAxAAAAHXPFcdxKyPToB0gK//xAAbEAACAwADAAAAAAAAAAAAAAABAgADEhEhIv/aAAgBAQABBQKluENjmNYxKaStLho+oRB3CZ//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAS/9oACAEDAQE/ATSv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEv/aAAgBAgEBPwEy7//EAB4QAAEEAQUAAAAAAAAAAAAAAAABAhAhESJCYXGB/9oACAEBAAY/AuDQ1E7Le/wVt0VuUzGY/8QAHRABAAMAAQUAAAAAAAAAAAAAAQARIWExQVFxwf/aAAgBAQABPyHcqxH80y38loe8AIbUnhjE5K6HjIMWOkuahKo3iOPZ9hP/2gAMAwEAAgADAAAAEFMYP//EABgRAAIDAAAAAAAAAAAAAAAAAAERABBh/9oACAEDAQE/EFkICr//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAECAQE/EBQlwxe//8QAHhABAAMAAgIDAAAAAAAAAAAAAQARITFBYYGRwdH/2gAIAQEAAT8QRuxo414I3O8GRfD7jFF/AGxSvbHg3dETgkuozSHil0EJLJS0nMWBN7hxlH9jn5qf/9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/95d45683e387d48e5c5d1dcaf5aa59c2/261e2/healing-house-8-min.jpg",
  //                     "srcSet": "/static/95d45683e387d48e5c5d1dcaf5aa59c2/6f45c/healing-house-8-min.jpg 3w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/f8e2e/healing-house-8-min.jpg 5w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/261e2/healing-house-8-min.jpg 10w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/f4935/healing-house-8-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/95d45683e387d48e5c5d1dcaf5aa59c2/fda91/healing-house-8-min.webp 3w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/fadb2/healing-house-8-min.webp 5w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/bd5c1/healing-house-8-min.webp 10w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/264f2/healing-house-8-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAMEBQIG/8QAFwEAAwEAAAAAAAAAAAAAAAAAAQIDAP/aAAwDAQACEAMQAAABm7z7qtRUw3qIwNnCNf/EABsQAQADAAMBAAAAAAAAAAAAAAECAxEAEhMj/9oACAEBAAEFAmX0QyUnafS1nW9dzmG3gQlXFf/EABcRAQADAAAAAAAAAAAAAAAAAAAQEUH/2gAIAQMBAT8BU2P/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEUH/2gAIAQIBAT8BViv/xAAcEAACAQUBAAAAAAAAAAAAAAAAASECEBESMSL/2gAIAQEABj8C1kc8IeDZlXpyieiMq3//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMVH/2gAIAQEAAT8h2bUsqb6SQxpTwRxcnHwrkh9C8XAlQ9GJG1IunT//2gAMAwEAAgADAAAAEMP/AD7/xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxAEN2U8SF//xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhMf/aAAgBAgEBPxBReWLO4We3/8QAHxABAAMAAgEFAAAAAAAAAAAAAQARITFRQWFxgbHB/9oACAEBAAE/EAFicBrPnPqGYUvZIp6EP2LRuGoD36viFqBG2rd6S07inOYyCuhxCemx7uyg1q8M/9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/6fd8333c9cf25a3a672484c424527be2/261e2/healing-house-21-min.jpg",
  //                     "srcSet": "/static/6fd8333c9cf25a3a672484c424527be2/6f45c/healing-house-21-min.jpg 3w,\n/static/6fd8333c9cf25a3a672484c424527be2/f8e2e/healing-house-21-min.jpg 5w,\n/static/6fd8333c9cf25a3a672484c424527be2/261e2/healing-house-21-min.jpg 10w,\n/static/6fd8333c9cf25a3a672484c424527be2/f4935/healing-house-21-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/6fd8333c9cf25a3a672484c424527be2/fda91/healing-house-21-min.webp 3w,\n/static/6fd8333c9cf25a3a672484c424527be2/fadb2/healing-house-21-min.webp 5w,\n/static/6fd8333c9cf25a3a672484c424527be2/bd5c1/healing-house-21-min.webp 10w,\n/static/6fd8333c9cf25a3a672484c424527be2/264f2/healing-house-21-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIEBQP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQIE/9oADAMBAAIQAxAAAAFfzbOd5TRW+sAqAR//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIRAxITBP/aAAgBAQABBQLz5Oc801Uk9lCDN6OqG6NmUj//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BI//EABgRAAIDAAAAAAAAAAAAAAAAAAARAQIQ/9oACAECAQE/AYuh7//EABsQAAICAwEAAAAAAAAAAAAAAAAhATEQESIy/9oACAEBAAY/AnUnLKPOzl6HECxR/8QAHRAAAgMAAgMAAAAAAAAAAAAAAAERITFhcUGBwf/aAAgBAQABPyFvQiK810vou2fRbSRbeGSSHB0JlsvkbCiJlZHkOj//2gAMAwEAAgADAAAAED/PQ//EABgRAAIDAAAAAAAAAAAAAAAAAAAhARAR/9oACAEDAQE/EENWQf/EABgRAQEAAwAAAAAAAAAAAAAAABEBABBB/9oACAECAQE/ECBcj5v/xAAeEAEBAAMAAgMBAAAAAAAAAAABEQAhMVFxQWGhsf/aAAgBAQABPxAKs6r76YoqRgOLvdcwcJeBv7hSHNQp9tZrRMb0e1wH4MtWGjma2h4JgoKj0cWMK3Eh/c//2Q=="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/c243d097e6eaf8b3774d966a23f4c6c7/261e2/healing-house-6-min.jpg",
  //                     "srcSet": "/static/c243d097e6eaf8b3774d966a23f4c6c7/6f45c/healing-house-6-min.jpg 3w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/f8e2e/healing-house-6-min.jpg 5w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/261e2/healing-house-6-min.jpg 10w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/f4935/healing-house-6-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/c243d097e6eaf8b3774d966a23f4c6c7/fda91/healing-house-6-min.webp 3w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/fadb2/healing-house-6-min.webp 5w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/bd5c1/healing-house-6-min.webp 10w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/264f2/healing-house-6-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUCBP/EABcBAQADAAAAAAAAAAAAAAAAAAIAAQP/2gAMAwEAAhADEAAAAddEG5nch2AtVSLAZ//EABsQAAICAwEAAAAAAAAAAAAAAAECAAQDESEj/9oACAEBAAEFAktODbfz7NxO13xabCg2IUWf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwFia//EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAECAQE/ASH/xAAZEAACAwEAAAAAAAAAAAAAAAABAgAQMSH/2gAIAQEABj8CBboMUrhsIdnLyf/EABoQAQEBAQEBAQAAAAAAAAAAAAEAESExQVH/2gAIAQEAAT8hTE+CZ6nbYizj6wMqh/YstZe8bcEh1O3/2gAMAwEAAgADAAAAEGDv/P/EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAIGT//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAECAQE/EBYjmcw3/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARITFBUWHB4f/aAAgBAQABPxDXO1Cs9MqQc4d1n2BChy+SDiONVZ+TR526N6jMAtBRSItYOFrITHKi9Bas/9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/cfec6bde048b4e46d5983f2391614ff2/261e2/healing-house-min.jpg",
  //                     "srcSet": "/static/cfec6bde048b4e46d5983f2391614ff2/6f45c/healing-house-min.jpg 3w,\n/static/cfec6bde048b4e46d5983f2391614ff2/f8e2e/healing-house-min.jpg 5w,\n/static/cfec6bde048b4e46d5983f2391614ff2/261e2/healing-house-min.jpg 10w,\n/static/cfec6bde048b4e46d5983f2391614ff2/f4935/healing-house-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/cfec6bde048b4e46d5983f2391614ff2/fda91/healing-house-min.webp 3w,\n/static/cfec6bde048b4e46d5983f2391614ff2/fadb2/healing-house-min.webp 5w,\n/static/cfec6bde048b4e46d5983f2391614ff2/bd5c1/healing-house-min.webp 10w,\n/static/cfec6bde048b4e46d5983f2391614ff2/264f2/healing-house-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAMBAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQD/2gAMAwEAAhADEAAAAaVmNu5jM9A3Au//xAAcEAACAgIDAAAAAAAAAAAAAAABAgADERITISL/2gAIAQEAAQUCUsr2k1qPUcqsS3ebM0K5OnfHmf/EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAEDAQE/AWP/xAAYEQACAwAAAAAAAAAAAAAAAAABEQACEP/aAAgBAgEBPwGpYce//8QAHhAAAgMAAQUAAAAAAAAAAAAAAAECESExEiIyQWH/2gAIAQEABj8C7+Pgmk5WeLM9GoxUbKRfVKzln//EABwQAQEBAAMAAwAAAAAAAAAAAAERADFBUSFxgf/aAAgBAQABPyFC9PCuqFHHm7jLPJhfnEiz3i6QGS8Zyavbif0FyErf73//2gAMAwEAAgADAAAAEAsYwP/EABcRAQEBAQAAAAAAAAAAAAAAABEBABD/2gAIAQMBAT8QkIYvf//EABkRAAIDAQAAAAAAAAAAAAAAAAABEBExQf/aAAgBAgEBPxDUFBLkf//EAB8QAQEAAgEEAwAAAAAAAAAAAAERACExQVGBkWGx8P/aAAgBAQABPxCki2Fg7P64H03i6zjziQiAxFm4PnnDcpud4nHvKOqJIOhjymnHGoKg06YwUeIU79MGT5kL9YtcmrBfRn//2Q=="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/3963305c6ef8a28cc56e2b09d99d4a86/261e2/healing-house-12-min.jpg",
  //                     "srcSet": "/static/3963305c6ef8a28cc56e2b09d99d4a86/6f45c/healing-house-12-min.jpg 3w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/f8e2e/healing-house-12-min.jpg 5w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/261e2/healing-house-12-min.jpg 10w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/f4935/healing-house-12-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/3963305c6ef8a28cc56e2b09d99d4a86/fda91/healing-house-12-min.webp 3w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/fadb2/healing-house-12-min.webp 5w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/bd5c1/healing-house-12-min.webp 10w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/264f2/healing-house-12-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         }
  //       ]
  //     }
  //   }
  // },
  // {
  //   "id": "zG9zZzDoxMTIyNw==",
  //   "title": "Energy Boost Mini Class",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-energy-boost",
  //   "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
  //   "datestimes": '',
  //   "eventsMetaData": {
  //     "datetime": "2025-04-30_19:30:00",
  //     "datetimeend": "2025-04-30_21:30:00",
  //     "timezone": "BST",
  //     "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
  //     "location": "Zoom",
  //     "lastspaces": null,
  //     "lastfewdaysearlybird": null,
  //     "workshopName": "Quantum-Touch Energy Boost",
  //     "eventpassed": null,
  //     "price": 45,
  //     "priceSale": null,
  //     "postalcode": null,
  //     "addresscountry": null,
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
  //     "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //   }
  // },
  {
    "id": "cG9zdDoxMDcxMA==",
    "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 4: Becoming Heart-Conscious",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-4",
    "excerpt": "This LIVE Quantum-Touch Level 4: Becoming Heart-Conscious (Formerly Level 2) workshop is online and spread over four 3-hour sessions.",
    "datestimes": '<ul>\n<li>Tuesday 6th May</li><li>Thursday 8th May</li><li>Tuesday 13th May</li><li>Wednesday 14th May</li></ul><p>Please note that all workshop sessions will start at 13:00 and end at approx. 16:15 BST</p>',
    "eventsMetaData": {
      "datetime": "2025-05-06_13:00:00",
      "datetimeend": "2025-05-06_16:15:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-march-in-london-2/",
      "location": "Zoom",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "workshopName": "Quantum-Touch Level 4: Becoming Heart-Conscious (Formerly Level 2)",
      "eventpassed": true,
      "price": 399,
      "priceSale": 349,
      "postalcode": null,
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": null,
      "stripeUrl": 'https://buy.stripe.com/28o5kpeLJd3i4PS19m',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/3cs6ot9rp9R60zC7xJ',
      "stripeUrlDeposit": 'https://buy.stripe.com/9AQ0058nlaVacik8BP',
      "stripeUrlRepeat": 'https://buy.stripe.com/bIYdQV0UT9R63LO2ds',
      "lunch": false
    }
  },
  {
    "id": "xF3zcccQ3TFyOM==",
    "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 5: Becoming Miraculous ",
    "excerpt": "This Quantum-Touch Level 5: Becoming Miraculous workshop is online and spread over 4 sessions, each session is just over 3 hours long.",
    "uri": "/events/quantum-touch",
    "eventType": "qt-becoming-miraculous",
    "datestimes": '<ul><li>Wednesday 4th June</li><li>Thursday 5th June</li><li>Wednesday 11th June</li><li>Thursday 12th June</li></ul><p>The workshop is scheduled to take place from 13:30 to 16:30 BST on all the specified dates.</p>',
    "eventsMetaData": {
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "datetime": "2025-06-04_13:30:00",
      "datetimeend": "2025-06-12_16:30:00",
      "timezone": "BST",
      "eventdatechange": null,
      "eventpassed": null,
      "fieldGroupName": "eventsMetaData",
      "location": "Zoom",
      // "lastspaces": true,
      "lastfewdaysearlybird": "",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-dec-online/",
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "onlineandlive": null,
      "postalcode": null,
      "price": 399,
      "priceSale": 349,
      "stripeUrl": 'https://buy.stripe.com/eVa3ch1YXd3i1DG8Cn',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/cN2cMR5b9aVagyAcSC',
      "stripeUrlDeposit": 'https://buy.stripe.com/14k7sxavt9R6aac05M',
      "stripeUrlRepeat": 'https://buy.stripe.com/5kAcMRdHFe7m0zCf1f',
      "workshopName": "Quantum-Touch Level 5: Becoming Miraculous "
    }
  },
  {
    "id": "bQ4zcDoxMTe1Ps==",
    "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 1: Becoming Energized",
    "excerpt": "This LIVE in person Quantum-Touch Level 1 workshop in London spread over 2 days",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-1",
    "datestimes": '<ul><li>Monday 9th June - 09:30 – 17:30 BST</li><li>Tuesday 10th June - 09:30 – 17:30 BST</li></ul>',
    "eventsMetaData": {
      "datetime": "2025-06-09_09:30:00",
      "datetimeend": "2025-06-10_17:30:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-1-september-in-london",
      "location": "Healing House",
      // "lastspaces": "Last 2 spaces available ",
      "lastfewdaysearlybird": "Live in London",
      "eventpassed": null,
      "price": 320,
      "priceSale": 265,
      "postalcode": "N22 8DH",
      "addressregion": "London",
      "addresslocality": "Bounds Green",
      "addresscountry": "GB",
      "workshopName": "Quantum-Touch Level 1",
      "stripeUrl": 'https://buy.stripe.com/fZe4gleLJ2oE5TW8BF',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/4gw005eLJ8N2cik7ym',
      "stripeUrlDeposit": 'https://buy.stripe.com/aEU3ch9rpaVa3LOaJT',
      "stripeUrlRepeat": 'https://buy.stripe.com/28ofZ39rpe7mcikaJU',
      "payPalUrl": 'https://www.paypal.com/',
      "payPalUrlEarlyBird": 'https://www.paypal.com/ncp/payment/GLJD7CQLWT7LLs',
      "payPalUrlDeposit": 'https://www.paypal.com',
      "payPalUrlRepeat": 'https://www.paypal.com',
      "lunch": true
    },
  },
  {
    "id": "vX4zcWocMTe4vh==",
    "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 1: Becoming Energized",
    "excerpt": "This Quantum-Touch Level 1 workshop is online and spread over 4 sessions, each session is just over 3 hours long.",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-1",
    "datestimes": '<ul><li>Tuesday 24th June</li><li>Wednesday 25th June</li><li>Monday 30th June</li><li>Wednesday 2nd July</li></ul><p>All sessions starts at 12:30 BST and finish at 16:00 BST</p>',
    "eventsMetaData": {
      "datetime": "2025-06-24_12:30:00",
      "datetimeend": "2025-07-02_16:00:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-1-september-in-london",
      "location": "Zoom",
      // "lastspaces": "Last 2 spaces available ",
      "lastfewdaysearlybird": "",
      "eventpassed": null,
      "price": 320,
      "priceSale": 265,
      "postalcode": "N22 8DH",
      "addressregion": "London",
      "addresslocality": "Bounds Green",
      "addresscountry": "GB",
      "workshopName": "Quantum-Touch Level 1",
      "stripeUrl": 'https://buy.stripe.com/fZe4gleLJ2oE5TW8BF',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/4gw005eLJ8N2cik7ym',
      "stripeUrlDeposit": 'https://buy.stripe.com/aEU3ch9rpaVa3LOaJT',
      "stripeUrlRepeat": 'https://buy.stripe.com/28ofZ39rpe7mcikaJU',
      "lunch": true
    },
  },
  // {
  //   "id": "cG9zdDoxxMDc2NQ==",
  //   "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 2: Becoming Supercharged",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-supercharging",
  //   "excerpt": "This LIVE Quantum-Touch Level 2: Becoming Supercharged workshop is online and spread over four 3-hour sessions.",
  //   "datestimes": '<ul>\n<li>Tuesday 24th June 14:00 – 17:15</li><li>Wednesday 25th June - 14:00 – 17:15</li><li>Tuesday 1st July - 14:00 – 17:15</li><li>Wednesday 2nd July - 14:00 – 17:15</li></ul>',
  //   "eventsMetaData": {
  //     "datetime": "2025-06-24_14:00:00",
  //     "datetimeend": "2025-07-02_17:15:00",
  //     "timezone": "GMT",
  //     "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-march-in-london/",
  //     "location": "Zoom",
  //     // "lastspaces": true,
  //     // "lastfewdaysearlybird": null,
  //     "eventpassed": null,
  //     "price": 399,
  //     "priceSale": 349,
  //     "postalcode": "N22 8DH",
  //     "addresscountry": "GB",
  //     "addresslocality": "Bounds Green",
  //     "addressregion": "London",
  //     "locationdescription": "A hidden oasis in London! Originally the gardener’s cottage for a Victorian market garden, now a beautifully renovated house for events and workshops.",
  //     "workshopName": "Quantum-Touch Supercharging",
  //     "stripeUrl": 'https://buy.stripe.com/6oEbIN0UT4wMbeg7xU',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/cN24gleLJbZe3LOdWh',
  //     "stripeUrlDeposit": 'https://buy.stripe.com/fZedQVcDB2oEeqs19t',
  //     "stripeUrlRepeat": 'https://buy.stripe.com/7sI005fPN0gwgyA2dy',
  //     "lunch": true,
  //     "upload_gallery": {
  //       "venueGallery": [
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBP/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdOYd2lDrM0zAP/EABwQAAEEAwEAAAAAAAAAAAAAABEAAQIDBBAhE//aAAgBAQABBQK/qlIvj2Q8xwBhouV//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAIQEf/aAAgBAwEBPwFqVf/EABgRAAIDAAAAAAAAAAAAAAAAAAABAhAR/9oACAECAQE/AWYRr//EABsQAAICAwEAAAAAAAAAAAAAAAARECICMUFC/9oACAEBAAY/Am0hvyXyscjgzcf/xAAcEAACAwEAAwAAAAAAAAAAAAABEQAhMVFBYYH/2gAIAQEAAT8hQAX2QajJQV5gV6xdZCC5I2pjAIWwdAvbiFoH2ESMo5//2gAMAwEAAgADAAAAENcvPf/EABkRAQEBAAMAAAAAAAAAAAAAAAEAESExUf/aAAgBAwEBPxA4MWB7dYDL/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERIWH/2gAIAQIBAT8QTS+mmNun/8QAHRABAAMAAwADAAAAAAAAAAAAAQARITFBkWFx8f/aAAgBAQABPxAGFDOF+RCisG3JXPIM3mD5cQgHRHC/bpBFQVBt/YiWH1hnyVFxmNAiVXQ0Mya1J//Z"
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/9a971bc2f452343aad203bf923932692/261e2/healing-house-14-min.jpg",
  //                     "srcSet": "/static/9a971bc2f452343aad203bf923932692/6f45c/healing-house-14-min.jpg 3w,\n/static/9a971bc2f452343aad203bf923932692/f8e2e/healing-house-14-min.jpg 5w,\n/static/9a971bc2f452343aad203bf923932692/261e2/healing-house-14-min.jpg 10w,\n/static/9a971bc2f452343aad203bf923932692/f4935/healing-house-14-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/9a971bc2f452343aad203bf923932692/fda91/healing-house-14-min.webp 3w,\n/static/9a971bc2f452343aad203bf923932692/fadb2/healing-house-14-min.webp 5w,\n/static/9a971bc2f452343aad203bf923932692/bd5c1/healing-house-14-min.webp 10w,\n/static/9a971bc2f452343aad203bf923932692/264f2/healing-house-14-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUBBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIBAP/aAAwDAQACEAMQAAABM4iqzCMAS0Rp/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAAxASEyEi/9oACAEBAAEFAjXQCrq04jLmrE3WK/WEPn//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/ATXf/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/ASP/xAAdEAEAAgAHAAAAAAAAAAAAAAABABECEiAhMTKR/9oACAEBAAY/AuGZBSd8fsKqGxp//8QAGhABAQEBAQEBAAAAAAAAAAAAAQARMUFR0f/aAAgBAQABPyFF2OIv7Zwdb7QHA3vbxA+obBnFbIsyYX//2gAMAwEAAgADAAAAEEAgf//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBFh/9oACAEDAQE/EFSNBR//xAAYEQEAAwEAAAAAAAAAAAAAAAABABARUf/aAAgBAgEBPxB1ZnEa/8QAHhABAQEAAgEFAAAAAAAAAAAAAREAITGBQVFhcaH/2gAIAQEAAT8Q4yE6Iv3hjeC28/K1n5kXwkoTxM8PQQ064982DzgGMyYBKveCrxiLnsUfW7//2Q=="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/8e8a72d777dd58627a29f986024cbae4/261e2/healing-house-13-min.jpg",
  //                     "srcSet": "/static/8e8a72d777dd58627a29f986024cbae4/6f45c/healing-house-13-min.jpg 3w,\n/static/8e8a72d777dd58627a29f986024cbae4/f8e2e/healing-house-13-min.jpg 5w,\n/static/8e8a72d777dd58627a29f986024cbae4/261e2/healing-house-13-min.jpg 10w,\n/static/8e8a72d777dd58627a29f986024cbae4/f4935/healing-house-13-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/8e8a72d777dd58627a29f986024cbae4/fda91/healing-house-13-min.webp 3w,\n/static/8e8a72d777dd58627a29f986024cbae4/fadb2/healing-house-13-min.webp 5w,\n/static/8e8a72d777dd58627a29f986024cbae4/bd5c1/healing-house-13-min.webp 10w,\n/static/8e8a72d777dd58627a29f986024cbae4/264f2/healing-house-13-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQADAQAAAAAAAAAAAAAAAAABAgQD/9oADAMBAAIQAxAAAAHXRGclNDWpx4oZJgj/xAAbEAADAQEAAwAAAAAAAAAAAAAAAQISAxETIv/aAAgBAQABBQKfgvFNonpDrXPwvUzCMSYk/8QAFREBAQAAAAAAAAAAAAAAAAAAICL/2gAIAQMBAT8Bkf/EABYRAQEBAAAAAAAAAAAAAAAAAAECIP/aAAgBAgEBPwFq1x//xAAeEAACAQMFAAAAAAAAAAAAAAAAAQIREiEQMUFxgf/aAAgBAQAGPwI57qYm2VvQ1dF+mHHXY//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExUWFB/9oACAEBAAE/Ic223PSk6t1pBB5neDBfe2O0Y7+iGwja+Dbg8J//2gAMAwEAAgADAAAAEOMPff/EABkRAQACAwAAAAAAAAAAAAAAAAEAERAhMf/aAAgBAwEBPxCgWIsTfc//xAAYEQEAAwEAAAAAAAAAAAAAAAABABARIf/aAAgBAgEBPxDpByDhf//EAB4QAAICAgMBAQAAAAAAAAAAAAEhABExQXGRwVGx/9oACAEBAAE/EAZdkBb1kqAHDsCh4KPUX6TYPJdTUKQL4LmRGVQTshD+fTofsaC7gFR+YKPGf//Z"
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/9f421a75e802b60b3b4da42665de5a7c/261e2/healing-house-19-min.jpg",
  //                     "srcSet": "/static/9f421a75e802b60b3b4da42665de5a7c/6f45c/healing-house-19-min.jpg 3w,\n/static/9f421a75e802b60b3b4da42665de5a7c/f8e2e/healing-house-19-min.jpg 5w,\n/static/9f421a75e802b60b3b4da42665de5a7c/261e2/healing-house-19-min.jpg 10w,\n/static/9f421a75e802b60b3b4da42665de5a7c/f4935/healing-house-19-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/9f421a75e802b60b3b4da42665de5a7c/fda91/healing-house-19-min.webp 3w,\n/static/9f421a75e802b60b3b4da42665de5a7c/fadb2/healing-house-19-min.webp 5w,\n/static/9f421a75e802b60b3b4da42665de5a7c/bd5c1/healing-house-19-min.webp 10w,\n/static/9f421a75e802b60b3b4da42665de5a7c/264f2/healing-house-19-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAMCAP/aAAwDAQACEAMQAAABtDg0CSa6zwOk1agK/wD/xAAbEAADAQADAQAAAAAAAAAAAAABAgMAERITQf/aAAgBAQABBQL37YczD0cNKum5ZjIsfooy5aEj/8QAFhEAAwAAAAAAAAAAAAAAAAAAAREg/9oACAEDAQE/AQo//8QAGREAAgMBAAAAAAAAAAAAAAAAAAECEBFR/9oACAECAQE/AZN8NjWI/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQACESEQIjFBgf/aAAgBAQAGPwI1SJb2rEKxJWrD6p0wHDnH/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERITFBUZH/2gAIAQEAAT8htZnij5W24ycQ0IN/Ug9KiV2oPrRfFeDzHssTgt3D/9oADAMBAAIAAwAAABD7H4P/xAAYEQEBAQEBAAAAAAAAAAAAAAABEQAQQf/aAAgBAwEBPxAzbHIrvef/xAAYEQEBAQEBAAAAAAAAAAAAAAABABExYf/aAAgBAgEBPxALhogjNnl5X//EABwQAQACAwEBAQAAAAAAAAAAAAEAESFRYTFx8P/aAAgBAQABPxAH0r7ZuU00eUK3+3MaNKNt9gOwNYyd0p2M2WJSHW/ke9F4pGpdVImCRmnWWm4Fznk//9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/e848534a83c536019a8011ddde743d64/261e2/healing-house-15-min.jpg",
  //                     "srcSet": "/static/e848534a83c536019a8011ddde743d64/6f45c/healing-house-15-min.jpg 3w,\n/static/e848534a83c536019a8011ddde743d64/f8e2e/healing-house-15-min.jpg 5w,\n/static/e848534a83c536019a8011ddde743d64/261e2/healing-house-15-min.jpg 10w,\n/static/e848534a83c536019a8011ddde743d64/f4935/healing-house-15-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/e848534a83c536019a8011ddde743d64/fda91/healing-house-15-min.webp 3w,\n/static/e848534a83c536019a8011ddde743d64/fadb2/healing-house-15-min.webp 5w,\n/static/e848534a83c536019a8011ddde743d64/bd5c1/healing-house-15-min.webp 10w,\n/static/e848534a83c536019a8011ddde743d64/264f2/healing-house-15-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAEDBAIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAGbsdovc89p1QBcgV//xAAcEAEBAAICAwAAAAAAAAAAAAACAQADERIhIjH/2gAIAQEAAQUCKRr2M4di631fNIbVXzJfKkt//8QAGREBAAIDAAAAAAAAAAAAAAAAAQACEBJR/9oACAEDAQE/ASpNHmCf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQERL/2gAIAQIBAT8Bs0p//8QAHhAAAQMEAwAAAAAAAAAAAAAAAAERIgIQEqEhMWH/2gAIAQEABj8CjolXwJizekHyUfVla3R//8QAGxABAQEBAQADAAAAAAAAAAAAAREAMSFRYXH/2gAIAQEAAT8hoCn3nuIsRDzVntKUXOEPknJobLWvw14Hn7N3wjnnnMtX1v/aAAwDAQACAAMAAAAQs/jB/8QAGhEAAwADAQAAAAAAAAAAAAAAAAERITFhkf/aAAgBAwEBPxBhyU7PC4Rof//EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAECAQE/EBTaLrG3T//EAB8QAQEAAgEEAwAAAAAAAAAAAAERACFBMVFhsYGh0f/aAAgBAQABPxBLoQLD14TNDOBKfCH5kgMkZE5wlmboIrZMuDbINd0x+iAC+rBVdunk36y6kNwNG3j4wTCmrXf3n//Z"
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/31671c22491eb0e54f9d15faa3a06dc2/261e2/healing-house-10-min.jpg",
  //                     "srcSet": "/static/31671c22491eb0e54f9d15faa3a06dc2/6f45c/healing-house-10-min.jpg 3w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/f8e2e/healing-house-10-min.jpg 5w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/261e2/healing-house-10-min.jpg 10w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/f4935/healing-house-10-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/31671c22491eb0e54f9d15faa3a06dc2/fda91/healing-house-10-min.webp 3w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/fadb2/healing-house-10-min.webp 5w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/bd5c1/healing-house-10-min.webp 10w,\n/static/31671c22491eb0e54f9d15faa3a06dc2/264f2/healing-house-10-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAdU08B81aGcVOAb/xAAaEAACAwEBAAAAAAAAAAAAAAABAgADEhET/9oACAEBAAEFAkoYTLR0bTpqXGtQMGea9CgTpn//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/ATFf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPwE13//EACEQAAECBAcAAAAAAAAAAAAAAAABAhAREjIDISIxcZGi/9oACAEBAAY/AlqdNeC/yLTik2l2o2TqGUP/xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMWFRcYH/2gAIAQEAAT8hao6hSxLYo4jGWnhN533ESDkwA3KNge4RoFfYM0qPmn//2gAMAwEAAgADAAAAEJQnAf/EABcRAQEBAQAAAAAAAAAAAAAAAAARAWH/2gAIAQMBAT8QyOio/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERYf/aAAgBAgEBPxC2uDGv/8QAHRABAQADAAIDAAAAAAAAAAAAAREAITFBUWFxgf/aAAgBAQABPxBHYTiTfW4GyExDjfrWUIK9t+d0wRCsXTjjcvgLZP6eDJpf1ByoI9CDEYVOmCYaHwZ//9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/8b7b96d40a99e8f6ffb82d99aff44c09/261e2/healing-house-11-min.jpg",
  //                     "srcSet": "/static/8b7b96d40a99e8f6ffb82d99aff44c09/6f45c/healing-house-11-min.jpg 3w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/f8e2e/healing-house-11-min.jpg 5w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/261e2/healing-house-11-min.jpg 10w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/f4935/healing-house-11-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/8b7b96d40a99e8f6ffb82d99aff44c09/fda91/healing-house-11-min.webp 3w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/fadb2/healing-house-11-min.webp 5w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/bd5c1/healing-house-11-min.webp 10w,\n/static/8b7b96d40a99e8f6ffb82d99aff44c09/264f2/healing-house-11-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAHp5kCygDrElwAv/8QAGhABAQEAAwEAAAAAAAAAAAAAAQACESEiI//aAAgBAQABBQJyTmeB+1q326fMt//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEBH/2gAIAQMBAT8BLjGf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEBH/2gAIAQIBAT8BNRKv/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAIxARARIHH/2gAIAQEABj8CtIrM1McJ1//EABsQAQEAAwADAAAAAAAAAAAAAAEAESExUWFx/9oACAEBAAE/IcwHS+Y3Gy1SLI+HEw9u3TqVBKyZv//aAAwDAQACAAMAAAAQPy+B/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EAwzP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAITH/2gAIAQIBAT8QUmls9v/EABwQAQADAAIDAAAAAAAAAAAAAAEAESExYUFRcf/aAAgBAQABPxDRligO1FjbztmSyPTuGwJr4NjHva8Aaq55ietDSJBwbCWDWA5z/9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/8bbe8a16c13420b157345a6cc06a0817/261e2/healing-house-9-min.jpg",
  //                     "srcSet": "/static/8bbe8a16c13420b157345a6cc06a0817/6f45c/healing-house-9-min.jpg 3w,\n/static/8bbe8a16c13420b157345a6cc06a0817/f8e2e/healing-house-9-min.jpg 5w,\n/static/8bbe8a16c13420b157345a6cc06a0817/261e2/healing-house-9-min.jpg 10w,\n/static/8bbe8a16c13420b157345a6cc06a0817/f4935/healing-house-9-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/8bbe8a16c13420b157345a6cc06a0817/fda91/healing-house-9-min.webp 3w,\n/static/8bbe8a16c13420b157345a6cc06a0817/fadb2/healing-house-9-min.webp 5w,\n/static/8bbe8a16c13420b157345a6cc06a0817/bd5c1/healing-house-9-min.webp 10w,\n/static/8bbe8a16c13420b157345a6cc06a0817/264f2/healing-house-9-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQH/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB1u0JBZVZZj3AvUDH/8QAGhAAAwADAQAAAAAAAAAAAAAAAAECBBIhMf/aAAgBAQABBQJvhsRUCpOloV5jU6tdP//EABYRAQEBAAAAAAAAAAAAAAAAABABEf/aAAgBAwEBPwHCn//EABYRAQEBAAAAAAAAAAAAAAAAABABEf/aAAgBAgEBPwHSH//EABoQAAIDAQEAAAAAAAAAAAAAAAARARAhMlH/2gAIAQEABj8CvslDiU9rfDT/xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMVFh/9oACAEBAAE/Ia8bcJiO3pItW1pMnDZxvpj8CF78YluO0QuE2sP/2gAMAwEAAgADAAAAEPcQ/P/EABcRAQEBAQAAAAAAAAAAAAAAAAEQIUH/2gAIAQMBAT8QWbOp/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQEUH/2gAIAQIBAT8QN2G4x//EABwQAQEAAwEAAwAAAAAAAAAAAAERACExQWGRof/aAAgBAQABPxDdphAHp84GiesHIelGM4OXKlbIibG9yQg1NCDz8xCAYSbtMFa6EfvNsZ0CemGh02bZn//Z"
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/1b935103b8c3f5f2b4d79333406f8b0b/261e2/healing-house-22-min.jpg",
  //                     "srcSet": "/static/1b935103b8c3f5f2b4d79333406f8b0b/6f45c/healing-house-22-min.jpg 3w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/f8e2e/healing-house-22-min.jpg 5w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/261e2/healing-house-22-min.jpg 10w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/f4935/healing-house-22-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/1b935103b8c3f5f2b4d79333406f8b0b/fda91/healing-house-22-min.webp 3w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/fadb2/healing-house-22-min.webp 5w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/bd5c1/healing-house-22-min.webp 10w,\n/static/1b935103b8c3f5f2b4d79333406f8b0b/264f2/healing-house-22-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQEBAQEAAAAAAAAAAAAAAAACAAED/9oADAMBAAIQAxAAAAHXPFcdxKyPToB0gK//xAAbEAACAwADAAAAAAAAAAAAAAABAgADEhEhIv/aAAgBAQABBQKluENjmNYxKaStLho+oRB3CZ//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAS/9oACAEDAQE/ATSv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEQEv/aAAgBAgEBPwEy7//EAB4QAAEEAQUAAAAAAAAAAAAAAAABAhAhESJCYXGB/9oACAEBAAY/AuDQ1E7Le/wVt0VuUzGY/8QAHRABAAMAAQUAAAAAAAAAAAAAAQARIWExQVFxwf/aAAgBAQABPyHcqxH80y38loe8AIbUnhjE5K6HjIMWOkuahKo3iOPZ9hP/2gAMAwEAAgADAAAAEFMYP//EABgRAAIDAAAAAAAAAAAAAAAAAAERABBh/9oACAEDAQE/EFkICr//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAECAQE/EBQlwxe//8QAHhABAAMAAgIDAAAAAAAAAAAAAQARITFBYYGRwdH/2gAIAQEAAT8QRuxo414I3O8GRfD7jFF/AGxSvbHg3dETgkuozSHil0EJLJS0nMWBN7hxlH9jn5qf/9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/95d45683e387d48e5c5d1dcaf5aa59c2/261e2/healing-house-8-min.jpg",
  //                     "srcSet": "/static/95d45683e387d48e5c5d1dcaf5aa59c2/6f45c/healing-house-8-min.jpg 3w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/f8e2e/healing-house-8-min.jpg 5w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/261e2/healing-house-8-min.jpg 10w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/f4935/healing-house-8-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/95d45683e387d48e5c5d1dcaf5aa59c2/fda91/healing-house-8-min.webp 3w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/fadb2/healing-house-8-min.webp 5w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/bd5c1/healing-house-8-min.webp 10w,\n/static/95d45683e387d48e5c5d1dcaf5aa59c2/264f2/healing-house-8-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAMEBQIG/8QAFwEAAwEAAAAAAAAAAAAAAAAAAQIDAP/aAAwDAQACEAMQAAABm7z7qtRUw3qIwNnCNf/EABsQAQADAAMBAAAAAAAAAAAAAAECAxEAEhMj/9oACAEBAAEFAmX0QyUnafS1nW9dzmG3gQlXFf/EABcRAQADAAAAAAAAAAAAAAAAAAAQEUH/2gAIAQMBAT8BU2P/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEUH/2gAIAQIBAT8BViv/xAAcEAACAQUBAAAAAAAAAAAAAAAAASECEBESMSL/2gAIAQEABj8C1kc8IeDZlXpyieiMq3//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMVH/2gAIAQEAAT8h2bUsqb6SQxpTwRxcnHwrkh9C8XAlQ9GJG1IunT//2gAMAwEAAgADAAAAEMP/AD7/xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxAEN2U8SF//xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhMf/aAAgBAgEBPxBReWLO4We3/8QAHxABAAMAAgEFAAAAAAAAAAAAAQARITFRQWFxgbHB/9oACAEBAAE/EAFicBrPnPqGYUvZIp6EP2LRuGoD36viFqBG2rd6S07inOYyCuhxCemx7uyg1q8M/9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/6fd8333c9cf25a3a672484c424527be2/261e2/healing-house-21-min.jpg",
  //                     "srcSet": "/static/6fd8333c9cf25a3a672484c424527be2/6f45c/healing-house-21-min.jpg 3w,\n/static/6fd8333c9cf25a3a672484c424527be2/f8e2e/healing-house-21-min.jpg 5w,\n/static/6fd8333c9cf25a3a672484c424527be2/261e2/healing-house-21-min.jpg 10w,\n/static/6fd8333c9cf25a3a672484c424527be2/f4935/healing-house-21-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/6fd8333c9cf25a3a672484c424527be2/fda91/healing-house-21-min.webp 3w,\n/static/6fd8333c9cf25a3a672484c424527be2/fadb2/healing-house-21-min.webp 5w,\n/static/6fd8333c9cf25a3a672484c424527be2/bd5c1/healing-house-21-min.webp 10w,\n/static/6fd8333c9cf25a3a672484c424527be2/264f2/healing-house-21-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIEBQP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQIE/9oADAMBAAIQAxAAAAFfzbOd5TRW+sAqAR//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIRAxITBP/aAAgBAQABBQLz5Oc801Uk9lCDN6OqG6NmUj//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BI//EABgRAAIDAAAAAAAAAAAAAAAAAAARAQIQ/9oACAECAQE/AYuh7//EABsQAAICAwEAAAAAAAAAAAAAAAAhATEQESIy/9oACAEBAAY/AnUnLKPOzl6HECxR/8QAHRAAAgMAAgMAAAAAAAAAAAAAAAERITFhcUGBwf/aAAgBAQABPyFvQiK810vou2fRbSRbeGSSHB0JlsvkbCiJlZHkOj//2gAMAwEAAgADAAAAED/PQ//EABgRAAIDAAAAAAAAAAAAAAAAAAAhARAR/9oACAEDAQE/EENWQf/EABgRAQEAAwAAAAAAAAAAAAAAABEBABBB/9oACAECAQE/ECBcj5v/xAAeEAEBAAMAAgMBAAAAAAAAAAABEQAhMVFxQWGhsf/aAAgBAQABPxAKs6r76YoqRgOLvdcwcJeBv7hSHNQp9tZrRMb0e1wH4MtWGjma2h4JgoKj0cWMK3Eh/c//2Q=="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/c243d097e6eaf8b3774d966a23f4c6c7/261e2/healing-house-6-min.jpg",
  //                     "srcSet": "/static/c243d097e6eaf8b3774d966a23f4c6c7/6f45c/healing-house-6-min.jpg 3w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/f8e2e/healing-house-6-min.jpg 5w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/261e2/healing-house-6-min.jpg 10w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/f4935/healing-house-6-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/c243d097e6eaf8b3774d966a23f4c6c7/fda91/healing-house-6-min.webp 3w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/fadb2/healing-house-6-min.webp 5w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/bd5c1/healing-house-6-min.webp 10w,\n/static/c243d097e6eaf8b3774d966a23f4c6c7/264f2/healing-house-6-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUCBP/EABcBAQADAAAAAAAAAAAAAAAAAAIAAQP/2gAMAwEAAhADEAAAAddEG5nch2AtVSLAZ//EABsQAAICAwEAAAAAAAAAAAAAAAECAAQDESEj/9oACAEBAAEFAktODbfz7NxO13xabCg2IUWf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwFia//EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAECAQE/ASH/xAAZEAACAwEAAAAAAAAAAAAAAAABAgAQMSH/2gAIAQEABj8CBboMUrhsIdnLyf/EABoQAQEBAQEBAQAAAAAAAAAAAAEAESExQVH/2gAIAQEAAT8hTE+CZ6nbYizj6wMqh/YstZe8bcEh1O3/2gAMAwEAAgADAAAAEGDv/P/EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAIGT//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAECAQE/EBYjmcw3/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARITFBUWHB4f/aAAgBAQABPxDXO1Cs9MqQc4d1n2BChy+SDiONVZ+TR526N6jMAtBRSItYOFrITHKi9Bas/9k="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/cfec6bde048b4e46d5983f2391614ff2/261e2/healing-house-min.jpg",
  //                     "srcSet": "/static/cfec6bde048b4e46d5983f2391614ff2/6f45c/healing-house-min.jpg 3w,\n/static/cfec6bde048b4e46d5983f2391614ff2/f8e2e/healing-house-min.jpg 5w,\n/static/cfec6bde048b4e46d5983f2391614ff2/261e2/healing-house-min.jpg 10w,\n/static/cfec6bde048b4e46d5983f2391614ff2/f4935/healing-house-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/cfec6bde048b4e46d5983f2391614ff2/fda91/healing-house-min.webp 3w,\n/static/cfec6bde048b4e46d5983f2391614ff2/fadb2/healing-house-min.webp 5w,\n/static/cfec6bde048b4e46d5983f2391614ff2/bd5c1/healing-house-min.webp 10w,\n/static/cfec6bde048b4e46d5983f2391614ff2/264f2/healing-house-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         },
  //         {
  //           "localFile": {
  //             "childImageSharp": {
  //               "gatsbyImageData": {
  //                 "layout": "constrained",
  //                 "placeholder": {
  //                   "fallback": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAMBAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQD/2gAMAwEAAhADEAAAAaVmNu5jM9A3Au//xAAcEAACAgIDAAAAAAAAAAAAAAABAgADERITISL/2gAIAQEAAQUCUsr2k1qPUcqsS3ebM0K5OnfHmf/EABURAQEAAAAAAAAAAAAAAAAAAAEg/9oACAEDAQE/AWP/xAAYEQACAwAAAAAAAAAAAAAAAAABEQACEP/aAAgBAgEBPwGpYce//8QAHhAAAgMAAQUAAAAAAAAAAAAAAAECESExEiIyQWH/2gAIAQEABj8C7+Pgmk5WeLM9GoxUbKRfVKzln//EABwQAQEBAAMAAwAAAAAAAAAAAAERADFBUSFxgf/aAAgBAQABPyFC9PCuqFHHm7jLPJhfnEiz3i6QGS8Zyavbif0FyErf73//2gAMAwEAAgADAAAAEAsYwP/EABcRAQEBAQAAAAAAAAAAAAAAABEBABD/2gAIAQMBAT8QkIYvf//EABkRAAIDAQAAAAAAAAAAAAAAAAABEBExQf/aAAgBAgEBPxDUFBLkf//EAB8QAQEAAgEEAwAAAAAAAAAAAAERACExQVGBkWGx8P/aAAgBAQABPxCki2Fg7P64H03i6zjziQiAxFm4PnnDcpud4nHvKOqJIOhjymnHGoKg06YwUeIU79MGT5kL9YtcmrBfRn//2Q=="
  //                 },
  //                 "images": {
  //                   "fallback": {
  //                     "src": "/static/3963305c6ef8a28cc56e2b09d99d4a86/261e2/healing-house-12-min.jpg",
  //                     "srcSet": "/static/3963305c6ef8a28cc56e2b09d99d4a86/6f45c/healing-house-12-min.jpg 3w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/f8e2e/healing-house-12-min.jpg 5w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/261e2/healing-house-12-min.jpg 10w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/f4935/healing-house-12-min.jpg 20w",
  //                     "sizes": "(min-width: 10px) 10px, 100vw"
  //                   },
  //                   "sources": [
  //                     {
  //                       "srcSet": "/static/3963305c6ef8a28cc56e2b09d99d4a86/fda91/healing-house-12-min.webp 3w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/fadb2/healing-house-12-min.webp 5w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/bd5c1/healing-house-12-min.webp 10w,\n/static/3963305c6ef8a28cc56e2b09d99d4a86/264f2/healing-house-12-min.webp 20w",
  //                       "type": "image/webp",
  //                       "sizes": "(min-width: 10px) 10px, 100vw"
  //                     }
  //                   ]
  //                 },
  //                 "width": 10,
  //                 "height": 10
  //               }
  //             }
  //           }
  //         }
  //       ]
  //     }
  //   }
  // },
  // {
  //   "id": "zG9zXXDoxMTIyNw==",
  //   "title": "Energy Boost Mini Class",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-energy-boost",
  //   "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
  //   "datestimes": '',
  //   "eventsMetaData": {
  //     "datetime": "2025-06-18_19:30:00",
  //     "datetimeend": "2025-06-18_21:30:00",
  //     "timezone": "BST",
  //     "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
  //     "location": "Zoom",
  //     "lastspaces": null,
  //     "lastfewdaysearlybird": null,
  //     "workshopName": "Quantum-Touch Energy Boost",
  //     "eventpassed": null,
  //     "price": 45,
  //     "priceSale": null,
  //     "postalcode": null,
  //     "addresscountry": null,
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
  //     "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //   }
  // },

  // {
  //   "id": "xF3zccZQ3DFyOM==",
  //   "title": "Quantum-Touch Manifesting Miracles",
  //   "excerpt": "This Quantum-Touch Manifesting Miracles workshop is online and spread over 4 sessions, each session is just over 3 hours long.",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-becoming-miraculous",
  //   "datestimes": '<ul><li>Monday 30th June</li><li>Monday 7th July</li><li>Monday 14th June</li><li>Monday 21stth June</li></ul><p>The workshop is scheduled to take place from 13:00 to 16:00 BST on all the specified dates.</p>',
  //   "eventsMetaData": {
  //     "addresscountry": null,
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "datetime": "2025-06-30_14:30:00",
  //     "datetimeend": "2025-07-21_17:30:00",
  //     "timezone": "BST",
  //     "eventdatechange": null,
  //     "eventpassed": null,
  //     "fieldGroupName": "eventsMetaData",
  //     "lastfewdaysearlybird": null,
  //     "lastspaces": null,
  //     "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-dec-online/",
  //     "location": "Zoom",
  //     "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
  //     "onlineandlive": null,
  //     "postalcode": null,
  //     "price": 399,
  //     "priceSale": 349,
  //     "stripeUrl": 'https://buy.stripe.com/eVa3ch1YXd3i1DG8Cn',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/cN2cMR5b9aVagyAcSC',
  //     "stripeUrlDeposit": 'https://buy.stripe.com/14k7sxavt9R6aac05M',
  //     "stripeUrlRepeat": 'https://buy.stripe.com/bIYdQV0UT9R63LO2ds',
  //     "workshopName": "Quantum-Touch Manifesting Miracles"
  //   }
  // },
  {
    "id": "hV9zcDoCMTeyOI==",
    "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 3: Becoming Whole",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-3",
    "excerpt": "This Quantum-Touch Level 3: Becoming Whole (Formerly Self-Created Health) workshop Live online over 4 sessions online.",
    "datestimes": '<ul><li>Tuesday 8th July 2025 - 09:00 – 12:30</li><li>Wednesday 9th July - 2025 09:00 – 12:30</li><li>Tuesday 15th July - 2025 09:00 – 12:30</li><li>Wednesday 16th July - 2025 09:00 – 12:30</li></ul>',
    "eventsMetaData": {
      "datetime": "2025-07-08_09:00:00",
      "datetimeend": "2025-07-09_12:30:00",
      "datetimeend": null,
      "timezone": "BST",
      "locationUrl": null,
      "location": "Zoom",
      "lastspaces": null,
      "lastfewdaysearlybird": null,
      "eventpassed": null,
      "price": 399,
      "priceSale": 349,
      "postalcode": null,
      "addresscountry": "GB",
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": null,
      "workshopName": "Quantum-Touch Self Created Health",
      "usPrice": 690,
      "stripeUrl": 'https://buy.stripe.com/bIYaEJ9rp4wM968aJZ',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/5kAcMRcDB1kAaaccSa',
      "stripeUrlDeposit": 'https://buy.stripe.com/dR64glgTR3sI8244lC',
      "stripeUrlRepeat": 'https://buy.stripe.com/3csdQV3316EU6Y09FX',
      "payPalUrl": 'https://www.paypal.com/',
      "payPalUrlEarlyBird": 'https://www.paypal.com/ncp/payment/GLJD7CQLWT7LLs',
      "payPalUrlDeposit": 'https://www.paypal.com',
      "payPalUrlRepeat": 'https://www.paypal.com',
      "lunch": false
    }
  },
  // {
  //   "id": "zG9zXXDoxMTIyQS==",
  //   "title": "Energy Boost Mini Class",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-energy-boost",
  //   "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
  //   "datestimes": '',
  //   "eventsMetaData": {
  //     "datetime": "2025-07-22_14:00:00",
  //     "datetimeend": "2025-07-22_16:00:00",
  //     "timezone": "BST",
  //     "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
  //     "location": "Zoom",
  //     "lastspaces": null,
  //     "lastfewdaysearlybird": null,
  //     "workshopName": "Quantum-Touch Energy Boost",
  //     "eventpassed": null,
  //     "price": 45,
  //     "priceSale": null,
  //     "postalcode": null,
  //     "addresscountry": null,
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
  //     "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //   }
  // },
  {
    "id": "cG9zdDfGMDc3MA==",
    "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 4: Becoming Heart-Conscious",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-4",
    "excerpt": "This Quantum-Touch Level 4: Becoming Heart-Conscious (Formerly Level 2) workshop is in London spread over 2 days.",
    "datestimes": '<ul><li>Monday 8th September 2025 - 09:30 – 17:30</li><li>Tuesday 9th September - 2025 09:30 – 17:30</li></ul>',
    "eventsMetaData": {
      "datetime": "2025-09-08_09:30:00",
      "datetimeend": "2025-09-09_17:30:00",
      "timezone": "BST",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-march-in-london-2/",
      "location": "Healing House",
      "lastspaces": true,
      "lastfewdaysearlybird": "Live in London",
      "workshopName": "Quantum-Touch Level 4: Becoming Heart-Conscious",
      "eventpassed": true,
      "price": 399,
      "priceSale": 349,
      "postalcode": "N22 8DH",
      "addresscountry": "GB",
      "addresslocality": "Bounds Green",
      "addressregion": "London",
      "locationdescription": "A hidden oasis in London! Originally the gardener’s cottage for a Victorian market garden, now a beautifully renovated house for events and workshops.",
      "stripeUrl": 'https://buy.stripe.com/28o5kpeLJd3i4PS19m',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/3cs6ot9rp9R60zC7xJ',
      "stripeUrlDeposit": 'https://buy.stripe.com/9AQ0058nlaVacik8BP',
      "stripeUrlRepeat": 'https://buy.stripe.com/bIYdQV0UT9R63LO2ds',
      "lunch": true
    }
  },
  // {
  //   "id": "zG9zXXDoxMTIyNS==",
  //   "title": "Energy Boost Mini Class",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-energy-boost",
  //   "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
  //   "datestimes": '',
  //   "eventsMetaData": {
  //     "datetime": "2025-09-18_14:00:00",
  //     "datetimeend": "2025-09-18_16:00:00",
  //     "timezone": "BST",
  //     "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
  //     "location": "Zoom",
  //     "lastspaces": null,
  //     "lastfewdaysearlybird": null,
  //     "workshopName": "Quantum-Touch Energy Boost",
  //     "eventpassed": null,
  //     "price": 45,
  //     "priceSale": null,
  //     "postalcode": null,
  //     "addresscountry": null,
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
  //     "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //   }
  // },
  // {
  //   "id": "cG9zdDoxMTIsMw==",
  //   "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 1: Becoming Energized",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-level-1",
  //   "excerpt": "This LIVE online Quantum-Touch Level 1 workshop starts on Tuesday 21st October and spread over 4 sessions",
  //   "datestimes": '<ul><li>Tuesday 21st October 14:00 – 21:15 BST</li><li>Wednesday 22nd October 14:00 – 17:15 BST</li><li>Tuesday 28th October 14:00 – 17:15 BST</li><li>Wednesday 29th October 14:00 – 17:15 BST</li></ul>',
  //   "eventsMetaData": {
  //     "datetime": "2025-10-21_14:00:00",
  //     "datetimeend": "2025-10-21_17:15:00",
  //     "timezone": "BST",
  //     "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-1-energy-healing-october-20204",
  //     "location": "Zoom",
  //     "lastspaces": null,
  //     "lastfewdaysearlybird": null,
  //     "eventpassed": null,
  //     "price": 320,
  //     "priceSale": 265,
  //     "postalcode": null,
  //     "addresscountry": "GB",
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "locationdescription": null,
  //     "workshopName": "Quantum-Touch Level 1",
  //     "stripeUrl": 'https://buy.stripe.com/fZe4gleLJ2oE5TW8BF',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/4gw005eLJ8N2cik7ym',
  //     "stripeUrlDeposit": 'https://buy.stripe.com/aEU3ch9rpaVa3LOaJT',
  //     "stripeUrlRepeat": 'https://buy.stripe.com/28ofZ39rpe7mcikaJU',
  //   }
  // },
  {
    "id": "G9jzdZDoGMDc3MA==",
    "title": "<abbr title='Quantum-Touch'>QT</abbr> Level 4: Becoming Heart-Conscious",
    "uri": "/events/quantum-touch",
    "eventType": "qt-level-4",
    "excerpt": "This Quantum-Touch Level 4 (Formerly Level 2) workshop is online spread over 4 sessions.",
    "datestimes": '<ul><li>Tuesday 11th November 2025</li><li>Wedenesday 12th November 2025</li><li>Tuesday 18th November 2025</li><li>Wedenesday 19th November 2025</li></ul><p> All sessions start at 13:00 and end at approx. 16:30 GMT </p>',
    "eventsMetaData": {
      "datetime": "2025-11-11_13:00:00",
      "datetimeend": "2025-11-15_16:30:00",
      "timezone": "GMT",
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-march-in-london-2/",
      "location": "Zoom",
      // "lastspaces": true,
      // "lastfewdaysearlybird": null,
      "workshopName": "Quantum-Touch Level 2",
      "eventpassed": true,
      "price": 399,
      "priceSale": 349,
      "postalcode": null,
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "locationdescription": null,
      "stripeUrl": 'https://buy.stripe.com/28o5kpeLJd3i4PS19m',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/3cs6ot9rp9R60zC7xJ',
      "stripeUrlDeposit": 'https://buy.stripe.com/9AQ0058nlaVacik8BP',
      "stripeUrlRepeat": 'https://buy.stripe.com/bIYdQV0UT9R63LO2ds',
      "lunch": true
    }
  },
  {
    "id": "xF9zcDoQ3TFyOM==",
    "title": "Quantum-Touch Level 5: Becoming Miraculous",
    "excerpt": "This Quantum-Touch Level 5: Becoming Miraculous  workshop is online and spread over 4 sessions, each session is just over 3 hours long.",
    "uri": "/events/quantum-touch",
    "eventType": "qt-becoming-miraculous",
    "datestimes": '<ul><li>Tuesday 2nd December</li><li>Wednesday 3rd December</li><li>Tuesday 9th December</li><li>Wednesday 10th December</li></ul><p>All sessions will start at 13:30 and will end at 16:30 GMT</p>',
    "eventsMetaData": {
      "addresscountry": null,
      "addresslocality": null,
      "addressregion": null,
      "datetime": "2025-12-02_13:30:00",
      "datetimeend": "2025-12-10_16:30:00",
      "timezone": "GMT",
      "eventdatechange": null,
      "eventpassed": null,
      "fieldGroupName": "eventsMetaData",
      "lastfewdaysearlybird": null,
      "lastspaces": null,
      "locationUrl": "https://www.karinagrant.co.uk/events/quantum-touch-level-2-dec-online/",
      "location": "Zoom",
      "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
      "onlineandlive": null,
      "postalcode": null,
      "price": 399,
      "priceSale": 349,
      "stripeUrl": 'https://buy.stripe.com/eVa3ch1YXd3i1DG8Cn',
      "stripeUrlEarlyBird": 'https://buy.stripe.com/cN2cMR5b9aVagyAcSC',
      "stripeUrlDeposit": 'https://buy.stripe.com/14k7sxavt9R6aac05M',
      "stripeUrlRepeat": 'https://buy.stripe.com/5kAcMRdHFe7m0zCf1f',
      "workshopName": "Quantum-Touch Level 5: Becoming Miraculous "
    }
  },
  // {
  //   "id": "mG9zXQDoxMTIyNS==",
  //   "title": "Energy Boost Mini Class",
  //   "uri": "/events/quantum-touch",
  //   "eventType": "qt-energy-boost",
  //   "excerpt": "This short 2-hour interactive mini-class is a wonderful way to introduce yourself to the world of energy healing and the infinite possibilities of Quantum-Touch.",
  //   "datestimes": '',
  //   "eventsMetaData": {
  //     "datetime": "2025-12-17_14:00:00",
  //     "datetimeend": "2025-12-17_16:00:00",
  //     "timezone": "GMT",
  //     "locationUrl": "https://www.karinagrant.co.uk/events/online-energy-boost-mini-class",
  //     "location": "Zoom",
  //     "lastspaces": null,
  //     "lastfewdaysearlybird": null,
  //     "workshopName": "Quantum-Touch Energy Boost",
  //     "eventpassed": null,
  //     "price": 45,
  //     "priceSale": null,
  //     "postalcode": null,
  //     "addresscountry": null,
  //     "addresslocality": null,
  //     "addressregion": null,
  //     "locationdescription": "Please log into Zoom a few minutes before the event, and mute your mic while you wait for the session to start",
  //     "stripeUrl": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //     "stripeUrlEarlyBird": 'https://buy.stripe.com/bIY7sxavtgfueqs05e',
  //   }
  // },
]
export default fixture

// Year	Clocks go forward	Clocks go back
// 2024	31 March	27 October
// 2025	30 March	26 October
// 2026	29 March	25 October